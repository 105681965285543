import React from 'react';
import {Box, Container, Center, HStack, Image, SimpleGrid, Stack, Text} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import TwoColumnsGrid from "../../OikosDesignSystem/TwoColumnsGrid";
import Column from "../../OikosDesignSystem/Sections/Column";
import Section from "../../OikosDesignSystem/Sections/Section";

const OurMission: React.FC = () => {

  const data = [
    "Advanced undergraduates",
    "Young professionals",
    "Part-time students",
    "Stay-at-home parents",
    "International students",
    "And many others"
  ]

  return (
    <>
      {/*<Section bg={"neutral-200"} py={"10"}>*/}
      

    <Center bg={"neutral-200"} px={{ base: '4', lg: '5' }}>
        <Center maxWidth={1200} w={"100%"}>
        <SimpleGrid columns={[1,1,1,2,2]} py={20} spacingX={[0, 0, 50]}>
            <ContentBlock
                title="OUR MISSION"
                titleColor={"primary-color-1"}
                subtitle={ "Helping learners"}
                subtitleColor={"neutral-800"}
                textAlign={"left"}
            >
                <Stack spacing={10}>
                <div className="inline-block font-medium text-neutral-600">
                    Education is the best avenue for social mobility across the world. Yet due to high costs and lack of personalized advising, students are doubting the value of a degree. We are working to improve educational outcomes for students by providing them access to high-quality advising with some of the world’s best educators, helping students increase social mobility.
                </div>
                <div className="inline-block font-medium text-neutral-600">
                    The educational landscape is also experiencing a demographic shift. Adult learners are the fasting growing segment in higher education around world today, while at the same time, they are one of the most underserved segments in education when it comes to personalized options. We want to change that.
                </div>
                <div className="inline-block font-medium text-neutral-600">
                    Though they may come from different backgrounds, adult learners are looking for educational support in the form of advising, guidance, and mentoring at key transitions in their personal and professional lives.
                </div>
                </Stack>
            </ContentBlock>

            <Center w={"100%"} h={"100%"} py={[10, 20, 40]}>
                <SimpleGrid columns={[1,1,1, 1, 2]} spacingY={5} spacingX={30}>
                    {
                    data.map(
                        (item) => {
                        return (
                            <HStack alignItems={'start'}>
                            <Image src="../group.svg"/>
                            <Text fontSize={"xl"} fontWeight={"bold"} w={"300px"}>
                                {item}
                            </Text>
                            </HStack>
                        )
                        }

                    )
                    }
                </SimpleGrid>
            </Center>
        </SimpleGrid>
        </Center>
    </Center>
    </>
  )
}

export default OurMission
