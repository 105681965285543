import { FunctionComponent } from "react";
import DiversitySection from "../components/Educators/DiversitySection";
import {Flex} from "@chakra-ui/react";
import EducatorsHero from "../components/Educators/EducatorsHero";
import NewWayToEngage from "../components/Educators/NewWayToEngage";
import OurMission from "../components/Educators/OurMission";
import TopicsSection from "../components/Educators/TopicsSection";
import OurCommunity from "../components/Educators/OurCommunity";
import PolisFeatures from "../components/Educators/PolisFeatures";


const Educators: FunctionComponent = () => {

  return (
      <Flex direction={"column"}>
        <EducatorsHero />
        <NewWayToEngage />
        <OurMission />
        <DiversitySection />
        <TopicsSection />
        <OurCommunity/>
        <PolisFeatures />
      </Flex>
  );
};

export default Educators;
