import React from 'react';
import {Card, Circle, CardBody, Heading, Stack, Image, Icon, IconButton, Box, HStack} from "@chakra-ui/react";
import { BsTwitter, BsLinkedin } from "react-icons/bs";

type FounderCardProps = {
  name: string;
  title: string;
  image: string;
  linkedin: string;
  twitter: string;
}


const FounderCard: React.FC<FounderCardProps> = ({ name, title, image, linkedin, twitter}) => {

  return (
    <Card w={"100%"} rounded={"20px"} shadow={"lg"}>
      <CardBody p={"0px"}>
        <Image src={image} objectFit={"cover"} boxSize={320} roundedTop={20}/>
        <Stack mt='50' spacing='21' alignItems={"center"}>
          <div className="relative box-border w-[78.75px] h-0.5 shrink-0 border-t-[2px] border-solid border-primary-color-11" />
          <b>{name}</b>
          <Box fontFamily={"lato"} color={"neutral-600"} fontWeight={"medium"} fontSize={"18"}>
            {title}
          </Box>
          <HStack spacing={"10"} pb="40px">
            <IconButton
              aria-label={twitter}
              onClick={() => {window.open(twitter, "_blank")}}
              icon={<Circle size='35px' bg='blue.400' color={"white"}>
              <Icon as={BsTwitter} boxSize={5} />
            </Circle>} />
            <IconButton
              aria-label={linkedin}
              onClick={() => {window.open(linkedin, "_blank")}}
              icon={<Circle size='35px' bg='blue.600' color={"white"}>
              <Icon as={BsLinkedin} boxSize={5} />
            </Circle>} />
          </HStack>
        </Stack>
      </CardBody>
    {/*<div className="relative w-[29.85px] h-[29.85px] shrink-0">*/}
    {/*  <div className="absolute top-[calc(50%_-_14.93px)] left-[calc(50%_-_14.93px)] w-[29.85px] h-[29.85px]">*/}
    {/*    <div className="absolute top-[calc(50%_-_14.93px)] left-[calc(50%_-_14.93px)] rounded-2xxl bg-dodgerblue-300 w-[29.85px] h-[29.85px]" />*/}
    {/*    <img*/}
    {/*      className="absolute top-[calc(50%_-_7.31px)] left-[calc(50%_-_7.67px)] w-[15.34px] h-[14.61px]"*/}
    {/*      alt=""*/}
    {/*      src="../vector6.svg"*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*</div>*/}
    </Card>
  )

}

export default FounderCard;
