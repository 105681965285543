import React, { FunctionComponent } from "react";
import {
    Box,
    Button,
    Card,
    Center,
    SimpleGrid,
    HStack,
    Stack,
    Image,
    Text,
} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import OikosButton from "../../OikosDesignSystem/OikosButton";
import { useNavigate } from "react-router-dom";
import ContactFormDrawer from "../ContactFormDrawer";

type Props = {
    title: string;
    subtitle: string;
    imgSrc: string;
};

const HowItWorksCard: React.FC<Props> = (props) => {
    return (
        <>
            <Card maxW={583} h={240} fontFamily={"lato"} rounded={20}>
                <Center h={"100%"} w={"100%"}>
                    <Stack direction={['column', 'row']}>
                        <Image px={10} maxW={{ base: '50%', md: 'none' }} src={props.imgSrc} />
                        <Stack pl={[10, 0]} pr={10}>
                            <Text
                                fontWeight={"bold"}
                                fontSize={24}
                                color={"neutral-800"}
                            >
                                {props.title}
                            </Text>
                            <Text
                                fontWeight={"medium"}
                                fontSize={18}
                                color={"neutral-600"}
                            >
                                {props.subtitle}
                            </Text>
                        </Stack>
                    </Stack>
                </Center>
            </Card>
        </>
    );
};

const HowItWorksSection: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <Center bg={"neutral-200"} px={{ base: "4", lg: "5" }}>
            <Box maxW={[1200]} px={{ base: '4', lg: '5' }} w={"100%"}>
                <Stack py={20}>
                    <SimpleGrid columns={[1, 1, 1, 1, 2]} maxW={1200} >
                        <Box maxW={550} pb={[0, 0, 20]}>
                            <ContentBlock
                                title={"How it works"}
                                // subtitle={"Personalizing education begins with the world’s best educators at your fingertips"}
                                textAlign={"left"}
                            >
                                Personalizing higher education begins with  accessible virtual advising. We partner with                                educational organizations to provide them access to
                                our large roster of top educational advisors to help meet the educational needs of their students.
                            </ContentBlock>
                        </Box>
                        <Box maxW={600} h={"100%"}>
                            <Center h={"100%"} w={"100%"}>
                                <HStack py={[10, 10, 20]} spacing={[4, 4, 10]}>
                                    {/*<Button bg={"primary-color-1"} color={"neutral-100"} variant={"solid"}> Get in touch</Button>*/}
                                    <ContactFormDrawer buttonText={"Contact Us"} />
                                    <OikosButton
                                        onClick={() => navigate("/howitworks")}
                                        buttonText={"Learn More"}
                                        variant={"outline"}
                                    />
                                </HStack>
                            </Center>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid columns={[1, 1, 1, 1, 2]} spacing={20}>
                        <HowItWorksCard
                            title={"Office Hours"}
                            subtitle={
                                "1:1 virtual office hours sessions that personalize educational experiences for students."
                            }
                            imgSrc={"../icon-sign-up.svg"}
                        />
                        <HowItWorksCard
                            title={"Top Advisors"}
                            subtitle={
                                "Educational advisors who are experts in their fields and bridge the academic and career divide."
                            }
                            imgSrc={"../icon-search.svg"}
                        />
                        <HowItWorksCard
                            title={"Personalized Matching"}
                            subtitle={
                                "Our ML+human matching tool helps learners find the right advisors for their needs and keeps track of their progress."
                            }
                            imgSrc={"../icon-book.svg"}
                        />
                        <HowItWorksCard
                            title={"Marketplace for Higher Education"}
                            subtitle={
                                "Our managed marketplace model provides cost-effective advisor roster management to meet the needs of higher education organizations."
                            }
                            imgSrc={"../icon-meet.svg"}
                        />
                    </SimpleGrid>
                </Stack>
            </Box>
        </Center>
    );
};

export default HowItWorksSection;
