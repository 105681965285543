import React from "react";
import { Center, Container, SimpleGrid, Card, Image, Stack, Text} from "@chakra-ui/react";
import {SectionSubtitle, SectionTitle} from "../../OikosDesignSystem/Typography";
import Section from "../../OikosDesignSystem/Sections/Section";

type Props = {
  title: string;
  color: string;
  imageSrc: string;
}

const FeatureBox: React.FC<Props> = (props) => {
  return (
    <Card w={['100%', '100%', 375]} h={['auto', 'auto', 375]} alignItems={"center"} rounded={20}>

      <Stack w={"100%"} h={"100%"} pt={50} alignItems={"center"} spacing={10}>
        <Image
          w={90}
          h={90}
          src={props.imageSrc}
        />
        <Text
          fontFamily={"lato"}
          fontWeight={"bold"}
          fontSize={24}
          color={"neutral-800"}
          textAlign={"center"}
          maxW={270}
        >
          {props.title}
        </Text>
        <Container
          bg={props.color}
          w={110}
          h={0.5}
        />
      </Stack>

    </Card>
  )
}

const PolisFeatures: React.FC = () => {
  return (
   <Section bg={"neutral-200"} py={10}>
     <Stack spacing={10}>
       <SectionTitle color={"primary-color-1"} textAlign={"center"}>THE POLIS EXPERIENCE</SectionTitle>
       <SectionSubtitle color={"neutral-800"} textAlign={"center"}>What to expect as an advisor</SectionSubtitle>
     <SimpleGrid pt={20} columns={[1, 1, 1, 2, 3]} spacing={10} px={{ base: '4', lg: '5' }}>
       <FeatureBox
          title={"Real-time sessions. No lectures. No grading. "}
          color={"secondary-color-6"}
          imageSrc={"../group-380282.svg"}
       />
       <FeatureBox
         title={"Secure messaging, booking, & meeting spaces."}
         color={"secondary-color-5"}
         imageSrc={"../group-380286.svg"}
       />
       <FeatureBox
         title={"Access to a growing global group of learners. "}
         color={"primary-color-1"}
         imageSrc={"../group-380283.svg"}
       />
       <FeatureBox
         title={"Control your hourly rate and availability."}
         color={"primary-color-1"}
         imageSrc={"../group-380283.svg"}
       />
       <FeatureBox
         title={"Secure direct payments to your bank account. "}
         color={"secondary-color-6"}
         imageSrc={"../group-380284.svg"}
       />
       <FeatureBox
         title={"Growth resources & networking events. "}
         color={"secondary-color-5"}
         imageSrc={"../group-380285.svg"}
       />
     </SimpleGrid>
     </Stack>
   </Section>
  )
}

export default PolisFeatures;
