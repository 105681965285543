import React from "react"
import {Center, Stack, Flex, Box, Hide, Image, Circle, Container} from "@chakra-ui/react"
import FAQCard from "../components/FAQ/FAQCard";
import { SectionTitle } from "../OikosDesignSystem/Typography";
import OikosLink from "../OikosDesignSystem/OikosLink";
import ContentBlock from "../OikosDesignSystem/Blocks/ContentBlock";

const Support: React.FC = () => {

  const linkColor = "secondary-color-6"

  const allUsers = [
    {
      title: "Office Hours Type Guide",
      content: [
        <>A 1-page guide that summarizes the types of office hours sessions that educators can offer to learners on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EQrUDWdtOdpPvg51rJCaN_MBYvQhL7o7UGGdpl9i85QR2w?e=xoqmJd"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Office Hours Playbook",
      content: [
        <>This playbook is a helpful resource for educators and learners that provides information about office hours, session planning, and summaries of typical sessions. </>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EV9PmggzA6VPiLCcVIsQD6wB74eibpoow2h3QdE561GWoQ?e=HK2w6W"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Expertise Keyword Guide",
      content: [
        <>This guide provides educators and learners with an overview of keyword phrases to optimize searches and user profiles.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EQvS0sDnQXROjFC6MhGY9pMBPVMeNgq35M9HBJq7Ojqwag?e=hDFwar"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    }
  ]

  const learners = [
    {
      title: "Learner Quick Start Guide",
      content: [
        <>A one-page guide with key information, links, and resources for learners to get started on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EbktOaIDnRJBt1ScNnIe_mQBmoIF0wAVi-UJ9shzrncosA?e=ZSOm9g"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Learner Profile Setup Guide",
      content: [
        <>This guide is designed specifically for learners. It helps learners to set up their profiles optimally on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EVQUQukpL8JLhUiqR1dqs7ABTA5XZAZ31xoAWAJDx4NDgw?e=Kv9W10"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Learner Onboarding Guide",
      content: [
        <>This onboarding guide is designed specifically for learners on Polis. This guide provides important information to learners about how Polis works, finding and selecting educators, office hours types, platform requirements, payment, cancellation, and other key aspects.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EYnxS8-4ab9EgSe-5yCulB8BY5NBeDvSP-_GgKfjS48dyg?e=j8fGEw"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    }
    ]

  const educators = [
    {
      title: "Educator Quick Start Guide",
      content: [
        <>A one-page guide with key information, links, and resources for educators to get started on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EVBfBrglbTNDmJ5G4sXDLCoBJkajKOnBXnMI2UOKPYBZ7Q?e=eodzH8"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Educator Onboarding Guide",
      content: [
        <>

          This onboarding guide is designed specifically for educators on Polis. It provides important information to educators about how Polis works, profile setup, platform requirements, payment, cancellation, and other key aspects.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EZXLX5aiF35Iv71EEBCOH0wBwI2Pjf5Ybo6xR3ITd_9NzA?e=cSMkf3"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "Setting Your Office Hours Rate Guide",
      content: [
        <>A 1-page guide that helps educators think about and set their hourly rate for office hours on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EdenQsfPLjhEtiEJHJWQiCUBRp3NowuXnw254TvZ7XuuIQ?e=2wt0HY"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    },
    {
      title: "How To Write Your \"About Me\" Guide",
      content: [
        <>This brief guide will help educator to write compelling About Me biographies for their Educator Profile on Polis.</>,
        <OikosLink
          href={"https://polis128-my.sharepoint.com/:b:/g/personal/michael_tworek_polis_ac/EVnoXBc9GWNIqWzzffzvGbsB0IPfuhomobpapuVR85V7gw?e=qy1Qgh"}
          color={linkColor}
        >
          Read Here
        </OikosLink>
      ]
    }
  ]

  return (
    <Flex direction={"column"} flex={1}>
      <Stack w={"100%"} alignItems={"center"} minH={800}>
        <Center  w={"100%"}>

          <Stack w={"100%"} spacing={-200}>
            <Box position={"relative"} bg={"primary-color-1"} w={"100%"} h={640}>

              {/* Triangle*/}
              <Hide below={"md"}>
                <Image
                  position={"absolute"}
                  top={0}
                  h={208}
                  w={208}
                  src={"../rectangle-4378.svg"}
                />
              </Hide>
              {/* Yellow Circle */}
              <Circle position={"absolute"} size={300} bg={"secondary-color-5"} left={-150} bottom={0}/>

              <Hide below={"md"}>
                <Circle position={"absolute"} size={208} bg={"secondary-color-5"} right={0} top={104}/>
                <Container
                  position={"absolute"}
                  w={208}
                  h={208}
                  top={0}
                  right={0}
                  bg={"secondary-color-3"}
                >
                  <Image
                    position={"absolute"}
                    top={0}
                    right={0}
                    src={"../rectangle-4377.svg"}

                  />

                  <Circle position={"absolute"} size={208} bg={"secondary-color-6"} right={0} top={312}/>
                </Container>
              </Hide>

              <Container position={"relative"} pt={12}>
                <ContentBlock
                  title="Resources"
                  titleColor={"neutral-500"}
                  subtitle={"How to get the most out of Polis."}
                  subtitleColor={"neutral-200"}
                  textAlign={"center"}
                >
                </ContentBlock>
              </Container>
            </Box>
            <Center width={"100%"} height={"100%"} py={5}>
              <FAQCard
                title={"All Users"}
                data={allUsers}
              />
            </Center>
          </Stack>

        </Center>

      </Stack>


      <Center width={"100%"} height={"100%"} py={5}>
      <FAQCard
        title={"Learners"}
        data={learners}
      />
      </Center>

      <Center width={"100%"} height={"100%"} py={5}>
      <FAQCard
        title={"Educators"}
        data={educators}
      />
      </Center>
</Flex>
)

}




export default Support
