import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import {Box, Center, Stack, SimpleGrid, Card, Image, Text, Container} from '@chakra-ui/react';
import {SectionSubtitle, SectionTitle} from "../../OikosDesignSystem/Typography";
import OikosButton from "../../OikosDesignSystem/OikosButton";

type Props = {
  title: string;
  body: string;
  body2?: string;
  color: string;
  imageSrc: string;
}

const BenefitBox: React.FC<Props> = (props) => {
  return (
    <Card w={['100%']} h={['auto', 'auto', 380]} p={4} alignItems={"center"} rounded={20}>

      <Stack w={"100%"} h={"100%"} pt={35} alignItems={"center"} spacing={7}>
        <Image
          w={90}
          h={90}
          src={props.imageSrc}
        />
        <Text
          fontFamily={"lato"}
          fontWeight={"bold"}
          fontSize={24}
          color={"neutral-800"}
          textAlign={"center"}
          maxW={270}
          
        >
          {props.title}
        </Text>
        <Text
          fontFamily={"lato"}
          fontWeight={"medium"}
          fontSize={[16, 18]}
          color={"neutral-600"}
          textAlign={"center"}
          maxW={280}
          pb={12}
        >
          {props.body}
        </Text>
        {/*<Text*/}
        {/*  fontFamily={"lato"}*/}
        {/*  fontWeight={"medium"}*/}
        {/*  fontSize={18}*/}
        {/*  color={"neutral-600"}*/}
        {/*  textAlign={"center"}*/}
        {/*  maxW={280}*/}
        {/*>*/}
        {/*  {props.body2}*/}
        {/*</Text>*/}

      </Stack>
      <Container
        position={"absolute"}
        bottom={10}
        bg={props.color}
        w={110}
        h={0.5}
      />
    </Card>
  )
}

const KeyBenefits: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Center bg={"neutral-200"} py={[20, 20, 40]} px={[10]}>
      <Box maxW={1200} w={'100%'} px={[0, 0, 5]}>
        <Stack spacing={10}>
          <SectionTitle color={"primary-color-1"} textAlign={"center"}>HOW POLIS CAN HELP YOU </SectionTitle>
          <Center>
          <SectionSubtitle fontSize={[24, 38]} color={"neutral-800"} textAlign={"center"} w={[350, null, 490]}>Democratizing access to the world’s best educational advisors</SectionSubtitle>
          </Center>
        <Center px={0}>
            <SimpleGrid pt={[0, 20]} columns={[1, 1, 1, 1, 3]} spacing={10} w={'100%'} borderColor='gray.200'>
              <BenefitBox
                title={"Academic Advising"}
                body={"With advisors from top universities, we provide virtual advising for subject enrichment, college planning, soft- and hard-skill building, and other topics."}
                // body2={"With advisors from top universities, we can provide virtual advising for subject enrichment, college planning, soft and hard skill building, and much more. "}
                imageSrc={"../icon.svg"}
                color={"secondary-color-6"}
              />
              <BenefitBox
                title={"Career Guidance"}
                body={"With advisors with industry experience, we offer virtual guidance for resume review, interview practice, career planning, and much more."}
                // body2={"With advisors with industry experience, we can offer virtual guidance for resume review, interview practice, career planning, and much more. "}
                imageSrc={"../icongroup.svg"}
                color={"secondary-color-5"}
              />
              <BenefitBox
                title={"Course Support"}
                body={"We provide universities with virtual course support for their students with writing feedback, assignment planning, research help, and additional course support."}
                // body2={"We provide universities with virtual course support for their students with writing feedback, assignment planning, research help, and much more. "}
                imageSrc={"../group-38028.svg"}
                color={"primary-color-1"}
              />
            </SimpleGrid>
          </Center>
          <Center>
          <OikosButton
            buttonText={"Learn More"}
            onClick={() => navigate("/howitworks")}
            w={['100%', '100%', 400]}
          />
          </Center>
        </Stack>
      </Box>
    </Center>
  );
};

export default KeyBenefits;
