import { FunctionComponent } from "react";
import { Box, Container, Center, SimpleGrid, Stack, Image } from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import ContactFormDrawer from "../ContactFormDrawer";
const HomeHero: FunctionComponent = () => {

  return (
    <Center>
      <Box maxW={[1200]} h={["auto","auto", "auto", 1400, 700]} px={{ base: '4', lg: '5' }} w={"100%"}>
        <SimpleGrid columns={[1, 1, 1, 1, 2]}  w={"100%"} spacing={[20, 20, 20, 20, 80]} >
          <Center h={"100%"} w={"100%"} pb={20}>
            <ContentBlock
              title={""}
              subtitle={"Access to the world’s best educational advisors — anytime, anywhere"}
              textAlign={"left"}
            >
              <Stack spacing={[10, 10, 20]}>
                <p> We provide organizations with personalized, data-driven access to 1:1 virtual advising to reduce student attrition and boost student engagement.
                </p>
                {/*<RequestLearnerAccessDrawer />*/}
                <ContactFormDrawer
                  buttonText={"Request a Demo"}
                />
              </Stack>
            </ContentBlock>
          </Center>

          <Box display={{ base: "none", lg: "block"}} position={"relative"} maxW={600} w={"100%"} h={700}>
            <Container
              position={"absolute"}
              top={0}
              right={0}
              bg={"secondary-color-3"}
              w={["100%", "100%", 300]}
              h={700}
            />
            <Image
              position={"absolute"}
              bottom={0}
              right={0}
              w={300}
              h={300}
              src="../rectangle-1514.svg"
            />
            <Image
              className="absolute top-0 left-0 w-[300px] h-[300px]"
              src="../ellipse-119.svg"
            />
            <Image
              // className="absolute top-0 right-0 w-300 h-300"
              position={"absolute"}
              top={0}
              right={0}
              w={300}
              h={300}
              src="../rectangle-4105.svg"
            />
            <Image
              rounded={20}
              position={"absolute"}
              maxW={["100%", "100%", 600]}
              // h={700}
              top={10}
              right={[null, null, 20]}
              src="../HomeHeroImage.jpeg"
            />
          </Box>
          {/*</Container>*/}
        </SimpleGrid>
      </Box>
    </Center>
  );
};

export default HomeHero;
