import { FunctionComponent, useCallback } from "react";
import CommunityHelpContainer from "../components/HowItWorks/CommunityHelpContainer";
import GrowthMarketingPlaybookContain from "../components/HowItWorks/GrowthMarketingPlaybookContain";
import RequestLearnerAccessContainer from "../components/HowItWorks/RequestLearnerAccessContainer";
import EducatorContainer from "../components/HowItWorks/EducatorContainer";
import { Box, Stack, Flex } from "@chakra-ui/react";

const HowItWorks: FunctionComponent = () => {


  return (

      <Flex direction={"column"}>
        <EducatorContainer />
        <RequestLearnerAccessContainer />
        <CommunityHelpContainer />
        <GrowthMarketingPlaybookContain />
      </Flex>
  );
};

export default HowItWorks;
