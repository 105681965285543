import React, {useState} from 'react'
import {Container, FormControl, FormLabel, HStack, Stack, Textarea} from "@chakra-ui/react";
import OikosInput from "../OikosDesignSystem/OikosInput";
import OikosButton from "../OikosDesignSystem/OikosButton";
import { SectionSubtitle } from "../OikosDesignSystem/Typography";
import {notification} from "antd/lib";

const ContactForm:React.FC = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')


  const firstNameError = firstName === ''
  const lastNameError = lastName === ''
  const emailError = email === ''
  const messageError = message === ''

  let isError = firstNameError || lastNameError || emailError || messageError

  const onSubmit = () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    console.log(email)

    const body = {
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "message": message,
    }

    const options = {
      method: "POST",
      headers,
      mode: "cors",
      body: JSON.stringify(body),
    }

    // @ts-ignore
    fetch("https://eolnrtq2msj8daq.m.pipedream.net", options)
      .then((response) => {
        notification.success({
          message: 'Success',
          description: 'Your message has been sent. We will get back to you shortly.',
        })
        setEmail('')
        setFirstName('')
        setLastName('')
        setMessage('')
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: 'There was an error sending your message. Please try again later.',
        })
      })
  }



  return (
    <Container bg={"neutral-100"} rounded={10} p={5}>

      <SectionSubtitle>Get in touch</SectionSubtitle>

      <FormControl pt={10}>
        <Stack spacing={5}>
          <HStack width={"100%"}>
            <Stack width={"100%"}>
              <FormLabel>First Name</FormLabel>
              <OikosInput value={firstName} onChange={(e: any) => {console.log(e)
                setFirstName(e.target.value)} } />
            </Stack>
            <Stack width={"100%"}>
              <FormLabel>Last Name</FormLabel>
              <OikosInput value={lastName} onChange={(e: any) => {setLastName(e.target.value)} } />
            </Stack>


          </HStack>
          <FormLabel>Email</FormLabel>
          <OikosInput type={email} value={email} onChange={(e: any) => {setEmail(e.target.value)} } />
          <FormLabel>Message</FormLabel>
          <Textarea
            borderColor={"primary-color-1"}
            variant="outline"
            rows={10}
            value={message}
            onChange={(e: any) => {setMessage(e.target.value)}}
          />
          <OikosButton
            buttonText={"Get in Touch"}
            onClick={onSubmit}
          />
          {/*{!firstNameError ? (*/}
          {/*  <FormHelperText>*/}
          {/*    Your first name.*/}
          {/*  </FormHelperText>*/}
          {/*) : (*/}
          {/*  <FormErrorMessage>First name is required.</FormErrorMessage>*/}
          {/*)}*/}

        </Stack>
      </FormControl>
    </Container>
  )
}

export default ContactForm
