import React from 'react';
import { Link } from '@chakra-ui/react';

type Props = {
  color?: string
  href: string
  [rest:string]: any;
}
const OikosLink: React.FC<Props>= ({color, href, ...rest}) => {

  const defaultColor = color ? color : "primary-color-1";

  return (
    <Link
      href={href}
      color={defaultColor}
      {...rest}
    >
    </Link>
  );
};

export default OikosLink;
