import { FunctionComponent } from "react";
import { Box, Center, Text, SimpleGrid, GridItem, Stack, HStack, Image} from "@chakra-ui/react"

const CommunityHelpContainer: FunctionComponent = () => {

  const data = [
    "Interview Prep",
    "Cover Letter Review",
    "Application Review",
    "CV/Resume Review",
    "Non-Academic Career Help",
    "Personal Statement Review",
    "Writing Feedback",
    "Guided Reading",
    "Proposal Review",
    "Research/Thesis Help",
    "Subject/Industry Expertise",
    "Informational Interview",
  ]

  return (
    <>
      <Center fontFamily={"lato"} color={"neutral-800"} bg={"neutral-300"}>
        <Stack py={"60px"}>
          <Center>
            <Text maxWidth={"525px"} fontSize={"4xl"} fontWeight={"bold"} textAlign={"center"}>
              How can our community of educators help you?
            </Text>
          </Center>

          <SimpleGrid p={"60px"} columns={[1, 2, 3]} spacingX={"40px"} spacingY={"20px"}>
            {
              data.map(
                (item) => {
                  return (
                    <HStack>
                      <Image src="../group.svg"/>
                      <Text fontSize={"xl"} fontWeight={"bold"} w={"350px"}>
                        {item}
                      </Text>
                    </HStack>
                  )
                }

              )
            }
          </SimpleGrid>
        </Stack>

      </Center>
    </>
  );
};

export default CommunityHelpContainer;
