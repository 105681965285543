import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Center,
  Container,
  Divider,
  Text
} from "@chakra-ui/react";

type FAQCardProps = {
  title: string,
  data: any,
}

const FAQCard: React.FC<FAQCardProps> = (props) => {
  return (

    <Container px={5} w={"100%"} maxW={1100}>
    <Card maxW={1100} w={"100%"} rounded={20} border='1px' borderColor='neutral-200'>
      <Center h={220}>
        <Text fontFamily={"lato"} fontWeight={"bold"} fontSize={40} color={"secondary-color-3"} textAlign={"center"} px={5}>
          {props.title}
        </Text>
      </Center>

      <Accordion className="self-stretch" allowToggle pb={20} px={[5, 5, 10, 20, 20]}>
        {props.data.map((item: any) => {
          return (
            <>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text color={"secondary-color-3"} fontSize={18} fontWeight={"bold"}>{item.title}</Text>
                </Box>
                <AccordionIcon/>
              </AccordionButton>
              <Divider h={0.5} maxW="100%" width={"100%"} bg={"primary-color-1"}/>
              <AccordionPanel pb={4}>
                {item.content.map((paragraph: string) => {
                  return(<Text py={3}>{paragraph}</Text>)
                  // return(<>{paragraph}</>)
                })}
              </AccordionPanel>
            </AccordionItem>

            </>
          )
        })}
      </Accordion>

    </Card>
    </Container>

  )
}

export default FAQCard;
