import React from "react";
import {Box, Center, Circle, Container, Image, Stack} from "@chakra-ui/react";
import OikosLink from "../../OikosDesignSystem/OikosLink";
import FAQCard from "./FAQCard";


const LearnersFAQ: React.FC = () => {

  const learners = [
    {
      title: "What can Polis offer to learners and students?",
      content: [
        <>We help you to personalize your educational experience to meet your academic and career goals. We do achieve this aim by matching and connecting you with the world's best educators and their services through our curated, proprietary approach. </>,
        <>Currently, you can access our educators through 1:1 online "office hours" sessions to find answers and solutions to your academic and career needs. You can learn more <OikosLink href={"howitworks"}>here</OikosLink>.</>,
        <>Stay tuned for future ways to access our educators and their services through our platform.</>
      ]
    },
    {
      title: "How is Polis different from other educational platforms?",
      content: [
        <>Polis differs from other options in two key ways. </>,
        <>First, we are building a roster of the world’s best educators who can bridge academic and career interests for learners and students in higher education. Instead of being limited in terms of interests, our learners can access the services, expertise, and experiences of educators from the top universities and organizations outside of academia. These learners can find answers to their academic and career questions at key transitions in their professional and personal life. </>,
        <>We believe that our educators are and should be the future faculty of higher education.</>,
        <>Second, we’ve developed a proprietary method of matching learners and educators together to solve short-term challenges and optimize long-term educational outcomes. The “how and why” behind are our approach is crucial. </>,
        <>At the core of this unique approach are research-proven insights based on andragogy (study of teaching adults) and the evidence-driven belief that technology should augment human component of educational experiences, not replaced them. </>,
        <>By better matching learners and educators together, we help both of them to save time, solve challenges, exchange knowledge, create accountability and opportunities for growth, build community, and achieve their personal and professional goals.  </>
      ]
    },
    {
      title: "Who are our educators?",
      content: [
        <>We take this claim seriously, but we believe that we have some of the world’s best educators on Polis. </>,
        <>Our educators are top academics in different fields from the world’s top universities. They are professors, lecturers, and advanced graduate students. </>,
        <>Our educators are also professionals with advanced degrees and substantial experience in various industries and sectors. They are startup founders, consultants, executives, non-profit administrators, and teachers. </>,
        <>Most of our educators possess advanced degrees (~97% have PhDs and terminal degrees, e.g. MBA, etc.). All of them have teaching experience, and the ability to bridge the academic and career divide for students in higher education and the workforce. </>,
        <>Our educators also receive onboarding and training resources to optimize their ability to personalize your educational experience on Polis. </>
      ]
    },
    {
      title: "How can I access the Polis platform?",
      content: [
        <>You can access the Polis platform in two ways currently. </>,
        <>First, you can receive access to Polis via your institution or organization if they are partnered with us. </>,
        <>If your organization or institution isn’t partnered with Polis currently (and you would like to recommend us) please contact us at polis@polis.ac. We can set up a demo for the relevant person at your organization.</>,
        <>Second, you can request individual access to the Polis by contact us or filling out our individual learner intake form <OikosLink href={"https://www.surveymonkey.com/r/polislearnerintakeform"}>here</OikosLink>. </>,
        <>We will review your intake form and get back to you ASAP about receiving access to our platform.</>,
        <>Polis is currently a web-based platform for desktop or laptop use only.  We are developing a version for mobile. Stay tuned!</>
      ]
    },
    {
      title: "How does payment work for learners on Polis?",
      content: [
        <>If your institution has a partnership with Polis, they will cover the cost of your use of our platform based on their subscription level. Please confer with your organization or contact us if you have any questions about your usage level and limits.</>,
        <>If you are a selected individual user, you pay as you go for your use of Polis via a credit card. All payments on Polis are done solely through Stripe, the world’s most secure and trusted payment provider. Through our Stripe integration, learners can pay with most major credit and debit cards. Currently, we do not accept direct bank debits or ACH transfers for payment.</>
      ]
    }
  ]


  return (
    <Center w={"100%"} h={800}>
        <FAQCard
          title={"Learners Questions"}
          data={learners}
        />
      </Center>
  )
}

export default LearnersFAQ;
