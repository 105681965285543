import { FunctionComponent, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CSS, { Property } from "csstype";
import {
  Box,
  Text,
  Container,
  Flex,
  Image,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  IconButton,
  useBreakpointValue,
  useColorModeValue
} from "@chakra-ui/react";
import { FiMenu } from 'react-icons/fi'
import ContactFormDrawer from "../../components/ContactFormDrawer";

type HeaderType = {
  polisLogoWebsiteHeader1?: string;

  /** Style props */
  primaryBTNBoxSizing?: Property.BoxSizing;
  buttonTextDisplay?: Property.Display;
  primaryBTNBoxSizing1?: Property.BoxSizing;
  buttonTextDisplay1?: Property.Display;
};

const Navbar: FunctionComponent<HeaderType> = ({
  primaryBTNBoxSizing,
  buttonTextDisplay,
  primaryBTNBoxSizing1,
  buttonTextDisplay1,
}) => {

  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const primaryBTNStyle: CSS.Properties = useMemo(() => {
    return {
      boxSizing: primaryBTNBoxSizing,
    };
  }, [primaryBTNBoxSizing]);

  const buttonText1Style: CSS.Properties = useMemo(() => {
    return {
      display: buttonTextDisplay,
    };
  }, [buttonTextDisplay]);

  const primaryBTN1Style: CSS.Properties = useMemo(() => {
    return {
      boxSizing: primaryBTNBoxSizing1,
    };
  }, [primaryBTNBoxSizing1]);

  const buttonText2Style: CSS.Properties = useMemo(() => {
    return {
      display: buttonTextDisplay1,
    };
  }, [buttonTextDisplay1]);

  return (
    <>
      <Box as="nav" role="navigation">
        <Box as="section" pb={{ base: '0', md: '0' }} >
          <Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
            <Container py={{ base: '4', lg: '5' }} fontFamily={"lato"} color={"neutral-600"} bg={"neutral-100"} maxW={"full"} w={1200}>
              <HStack spacing="0" justify="space-between" >
                {/*<Logo p={"0"} alignItems={"start"}/>*/}
                 <Image
                  className="cursor-pointer"
                   src={"/assets/polis-logo-websitenavbar.svg"}
                   onClick={() => {navigate("/")}}
                 />
                {isDesktop ? (
                  <Flex direction={"row"} gap={6} flex="1" justify={"end"} alignItems={"center"} w={"full"}>
                    {/*<ButtonGroup variant="link" spacing="8">*/}
                    {/*  {['Product', 'Pricing', 'Resources', 'Support'].map((item) => (*/}
                    {/*    <Button key={item}>{item}</Button>*/}
                    {/*  ))}*/}
                    {/*</ButtonGroup>*/}
                    {/*<Flex direction={"row"} justify={"end"} gap={"24px"} alignItems={"center"} w={"full"} >*/}
                          <div
                            className="relative leading-[20px] font-medium inline-block w-[49px] shrink-0 cursor-pointer"
                            onClick={() => {navigate("/")}}
                          >
                            Home
                          </div>
                          <div
                            className="relative leading-[20px] font-medium cursor-pointer"
                            onClick={() => {navigate("/about")}}
                          >
                            About
                          </div>
                          <div
                            className="relative font-medium cursor-pointer"
                            onClick={() => {navigate("/howitworks")}}
                          >
                            How It Works
                          </div>
                          <Text
                            className="relative leading-[20px] font-medium cursor-pointer"
                            onClick={() => {navigate("/educators")}}
                          >
                            Educators
                          </Text>
                          {/*<div*/}
                          {/*  className="relative leading-[20px] font-medium cursor-pointer"*/}
                          {/*  onClick={() => {navigate("/blog")}}*/}
                          {/*>*/}
                          {/*  Blog*/}
                          {/*</div>*/}
                          <div
                            className="relative leading-[20px] font-medium inline-block w-9 h-[19px] shrink-0 cursor-pointer"
                            onClick={() => {navigate("/faq")}}
                          >
                            FAQ
                          </div>
                          {/*<Button*/}
                          {/*  className="cursor-pointer [border:none] py-3.5 px-6 bg-neutral-300 rounded-lg flex flex-col items-center justify-center"*/}
                          {/*  style={primaryBTNStyle}*/}
                          {/*>*/}
                          {/*  <b*/}
                          {/*    className="relative text-xs leading-[18px] font-lato text-neutral-800 text-center"*/}
                          {/*    style={buttonText1Style}*/}
                          {/*  >*/}
                          {/*    Log In*/}
                          {/*  </b>*/}
                          {/*</Button>*/}
                          <ContactFormDrawer
                            buttonText={"Get in touch"}
                            variant={"yellow"}
                          />
                      {/*<Button variant="ghost"*/}
                      {/*        onClick={() => {}}>*/}
                      {/*  Sign in*/}
                      {/*</Button>*/}
                      {/*<Button variant="primary">Sign up</Button>*/}
                    {/*</Flex>*/}
                  </Flex>
                ) : (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      variant="ghost"
                      icon={<FiMenu fontSize="1.25rem" />}
                      aria-label="Open Menu"
                    />
                    <MenuList minWidth='240px'>
                      <MenuItemOption
                        onClick={() => {navigate("/")}}
                        value='asc'>Home
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {navigate("/about")}}
                        value='asc'>About
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {navigate("/howitworks")}}
                        value='asc'>How It Works
                      </MenuItemOption>
                      <MenuItemOption
                        onClick={() => {navigate("/educators")}}
                        value='asc'>Educators
                      </MenuItemOption>
                      {/*<MenuItemOption*/}
                      {/*  onClick={() => {navigate("/blog")}}*/}
                      {/*  value='asc'>Blog*/}
                      {/*</MenuItemOption>*/}
                      <MenuItemOption
                        onClick={() => {navigate("/faq")}}
                        value='asc'>FAQ
                      </MenuItemOption>
                      {/*<MenuItemOption*/}
                      {/*  onClick={() => {}}*/}
                      {/*  value='asc'>Sign In*/}
                      {/*</MenuItemOption>*/}
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </Container>
          </Box>
        </Box>
      </Box>
    {/*<Box pt="24px" pr={"37px"} fontFamily={"lato"} color={"neutral-600"} bg={"neutral-100"} >*/}
    {/*  /!*<div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-neutral-100" />*!/*/}
    {/*  /!*<Box className="absolute top-[24px] right-[37px] flex flex-row items-center  gap-[24px]">*!/*/}
    {/*  <HStack>*/}
    {/*  <Image*/}
    {/*    // className="absolute top-[18px] left-[133px] w-[116px] h-[59px] overflow-hidden"*/}
    {/*    // alt=""*/}
    {/*    src={"/assets/polis-logo-websitenavbar.svg"}*/}
    {/*  />*/}
    {/*  <Flex direction={"row"} justify={"end"} gap={"24px"} alignItems={"center"} >*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium inline-block w-[49px] shrink-0 cursor-pointer"*/}
    {/*      onClick={() => {navigate("/")}}*/}
    {/*    >*/}
    {/*      Home*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium cursor-pointer"*/}
    {/*      onClick={() => {navigate("/about")}}*/}
    {/*    >*/}
    {/*      About*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium cursor-pointer"*/}
    {/*      onClick={() => {navigate("/howitworks")}}*/}
    {/*    >*/}
    {/*      How It Works*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium cursor-pointer"*/}
    {/*      onClick={() => {navigate("/educators")}}*/}
    {/*    >*/}
    {/*      Educators*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium cursor-pointer"*/}
    {/*      onClick={() => {navigate("/blog")}}*/}
    {/*    >*/}
    {/*      Blog*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative leading-[20px] font-medium inline-block w-9 h-[19px] shrink-0 cursor-pointer"*/}
    {/*      onClick={() => {navigate("/faq")}}*/}
    {/*    >*/}
    {/*      FAQ*/}
    {/*    </div>*/}
    {/*    <button*/}
    {/*      className="cursor-pointer [border:none] py-3.5 px-6 bg-neutral-300 rounded-lg flex flex-col items-center justify-center"*/}
    {/*      style={primaryBTNStyle}*/}
    {/*    >*/}
    {/*      <b*/}
    {/*        className="relative text-xs leading-[18px] font-lato text-neutral-800 text-center"*/}
    {/*        style={buttonText1Style}*/}
    {/*      >*/}
    {/*        Log In*/}
    {/*      </b>*/}
    {/*    </button>*/}
    {/*    <button*/}
    {/*      className="cursor-pointer [border:none] py-3.5 px-6 bg-secondary-color-51 rounded-lg flex flex-col items-center justify-center"*/}
    {/*      style={primaryBTN1Style}*/}
    {/*    >*/}
    {/*      <b*/}
    {/*        className="relative text-xs leading-[18px] font-lato text-neutral-800 text-center"*/}
    {/*        style={buttonText2Style}*/}
    {/*      >*/}
    {/*        Get in touch*/}
    {/*      </b>*/}
    {/*    </button>*/}
    {/*  </Flex>*/}
    {/*  </HStack>*/}

    {/*  <div className="absolute top-[93.5px] left-[-0.5px] box-border w-[1441px] h-px border-t-[1px] border-solid border-neutral-300" />*/}
    {/*  <img*! */}
    {/*  className="absolute top-[18px] left-[133px] w-[116px] h-[59px] overflow-hidden" */}
    {/*  alt="" */}
    {/*  src={"/assets/polis-logo-websitenavbar.svg"} */}
    {/*  /> */}
    {/*</Box>*/}
    </>
  );
};

export default Navbar;
