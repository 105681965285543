import {FunctionComponent} from "react";
import {Container, Center, HStack, Image, SimpleGrid, Stack, Box} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import Section from "../../OikosDesignSystem/Sections/Section";
import ContactFormDrawer from "../ContactFormDrawer";
import TwoColumnsGrid from "../../OikosDesignSystem/TwoColumnsGrid";
import Column from "../../OikosDesignSystem/Sections/Column";

const EducatorsHero: FunctionComponent = () => {

  return (
    <>

    <Center bg={"secondary-color-2"} px={{ base: '4', lg: '5' }}>
        <Box maxWidth={"1200px"} w={"100%"} h={['auto', 'auto', 550]} >
            <SimpleGrid  columns={[1, 1, 1, 2, 2]}  w={"100%"} spacingX={[0, 0, 30]}>
            <Center h={"100%"} w={"100%"} py={10}>
                <ContentBlock
                    title={"EDUCATORS"}
                    titleColor={"primary-color-1"}
                    subtitle={"Transforming higher education begins with you"}
                    subtitleColor={"neutral-800"}
                    textAlign={"left"}
                >   
                <Stack spacing={20}>
                    <div className="inline-block font-medium text-neutral-600">
                    Join our global community of the world’s best educators who are creating impact with their expertise for learners around the world.
                    </div>
                    <ContactFormDrawer
                    buttonText={"Contact Us"}
                    />
                </Stack>
                </ContentBlock>
            </Center>

            <Center display={{ base: "none", lg: "block"}}  position={"relative"} h={"100%"} w={"100%"} >
                <Box maxWidth={"600px"} h={"550px"}>
                    <Image
                    position={"absolute"}
                    top={0}
                    right={0}
                    // w={"50%"}
                    w={["100%", "100%", "100%", "auto", "auto"]}
                    // h={["100%", "100%", "100%", 550, 550]}
                    h={550}
                    src="../group-39747.svg"
                    />
                    <Image
                    px={5}
                    position={"absolute"}
                    top={70}
                    right={[null, null, null, 20, 100]}
                    h={550}
                    src="../mask-group12@2x.png"
                    />
                </Box>
            </Center>

            </SimpleGrid>
        </Box>
    </Center>
    </>
  )

}

export default EducatorsHero
