import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WhyPolis from "../components/About/WhyPolis";
import AboutHeroSection from "../components/About/AboutHeroSection";
import PolisTeamSection from "../components/About/PolisTeamSection";
import AboutSection from "../components/About/AboutSection";
import {Box, Text, Stack} from "@chakra-ui/react";

const About: FunctionComponent = () => {


  return (
    <Box fontFamily={"lato"} color={"whiteAlpha.800"} fontSize={"4xl"} overflow={'hidden'}>
      {/*<div className="relative flex flex-col items-center justify-start w-full text-4xl text-center text-neutral-800 font-lato">*/}

        <AboutHeroSection />
        <Box  pt={"150px"} pb={20} px={5} textAlign={"center"}>
          <Stack spacing={50}>
          <b className="text-xs tracking-[0.1em] leading-[18px] uppercase font-literata text-primary-color-11">
            OUR NAME
          </b>

            <Text
              fontWeight={"bold"}
              alignSelf={"center"}
              color={"neutral-800"}
              maxW={"600px"}
              w={"full"}
            >
              <p>Polis means “city” or “community” in ancient Greek. </p>

            </Text>
          <Text

            w={"100%"}
            maxWidth={"530px"}
            alignSelf={"center"}
            className="text-base font-medium text-neutral-600"
          >
            We want to build a more inclusive and impactful community of learners for the 21st Century by personalizing higher education through access to 1:1 advising and mentoring.
          </Text>
          </Stack>
        </Box>

        <PolisTeamSection />

        <AboutSection />

        <WhyPolis />

    </Box>
  );
};

export default About;
