import React from 'react'
import {Container} from "@chakra-ui/react";

const ToS: React.FC = () => {
   return (
     <Container maxW={"100%"} maxH={"100%"}  height={"100%"} p={10}>
        <iframe
          width={"100%"} height={"100%"}
          src={"../tos.html"}
        />
     </Container>
   )

}

export default ToS
