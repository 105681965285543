import React from "react"
import { Container, Flex} from "@chakra-ui/react"
import ContentBlock from "../OikosDesignSystem/Blocks/ContentBlock";

const Support: React.FC = () => {

  return (
    <Flex direction={"column"} flex={1}>
      <Container py={20}>
        <ContentBlock
          title={"Support"}
          textAlign={"center"}
        >
          Learners and educators can find personalized live support options below to optimize their experience on Polis.
        </ContentBlock>
      </Container>
      <iframe
        src="https://app.acuityscheduling.com/schedule.php?owner=19368996"
        title="Schedule Appointment"
        width="100%"
        height="1000"
        frameBorder="0"/>
      <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"/>
    </Flex>
  )

}

export default Support
