import { FunctionComponent } from "react";
import {Center, Container, Image, Box, Stack, SimpleGrid} from "@chakra-ui/react";
import FounderCard from "./FounderCard";

const PolisTeamSection: FunctionComponent = () => {
  return (
    <Box pos={"relative"} w={"100%"} h={[1700, 1700, 1700, 1700, 500]} bg={"secondary-color-3"} className="text-lg text-center text-neutral-800 font-lato">

        {/*<div className="absolute top-[0px] right-[0.1px] bg-primary-color-11 w-[242.39px] h-[233.58px]" />*/}
        <img
          className="absolute bottom-0 left-[0px] w-[135.9px] h-[275px]"
          alt=""
          src="../ellipse-412.svg"
        />
        <Image
          position={"absolute"}
          top={0}
          left={0}
          h={225}
          w={225}
          src="../rectangle-4108.svg"
        />
        <Image
          position={"absolute"}
          top={[250, 250,  0, 0]}
          right={0}
          h={230}
          w={230}
          src="../ellipse-419.svg"
        />

        <Container maxW={"1200"} position={"relative"}>
        {/*<div className="absolute w-[calc(100%_-_285px)] top-[265px] right-[147px] left-[138px] h-[590px]">*/}
        <Box pt={"100px"} justifyContent={"center"}>
        <b  className="text-xs tracking-[0.1em] uppercase inline-block font-literata text-neutral-200 h-[18.7px]">
          OUR TEAM
        </b>
        </Box>
        <Box pt={"50px"} justifyContent={"center"}>
        <b className="inline-block text-4xl text-neutral-100 ">
          Meet the Polis team
        </b>
        </Box>
        {/*<Stack direction={"row"} spacing={"20"} justifyContent={"center"} pt={"50px"}>*/}
      <Center>
        <SimpleGrid pt={20} columns={[1,1,1,1,3]} spacingX={8} spacingY={10}>
          <FounderCard
              image={"../michael@2x.png"}
              name={"Michael Tworek"}
              title={"Chief Executive Officer"}
              linkedin={"https://www.linkedin.com/in/mttworek/"}
              twitter={"https://twitter.com/michaeltworek"}
            />
            <FounderCard
              image={"../matthew_corcoran.jpeg"}
              name={"Matthew Corcoran"}
              title={"Chief Operating Officer"}
              linkedin={"https://www.linkedin.com/in/matthewjcorcoran"}
              twitter={"https://twitter.com/mjcorcor"}
            />
            <FounderCard
              image={"../Jose_Fernandez-Alcon-1024px-800x800.jpg"}
              name={"Jose Fernandez-Alcon"}
              title={"Chief Technology Officer"}
              linkedin={"https://www.linkedin.com/in/josefernandezalcon"}
              twitter={"https://twitter.com/epiphyteio"}
            />
        </SimpleGrid>
      </Center>
        </Container>
        {/*</div>*/}

      {/*</div>*/}
    </Box>
  );
};

export default PolisTeamSection;
