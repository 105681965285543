import { FunctionComponent } from "react";
import { Box, Center, SimpleGrid, Text, Image, Stack } from '@chakra-ui/react';
import RequestLearnerAccessDrawer from "../RequestLearnerAccessDrawer";
import ContactFormDrawer from "../ContactFormDrawer";

const RequestLearnerAccessContainer: FunctionComponent = () => {


  return (
    <Center pt={50}>
    <Box maxWidth={"1440px"} w={"100%"}>
      <Stack alignItems={"center"}>
        <Text pt="150px" className="text-xs tracking-[0.1em] leading-[18px] uppercase inline-block font-literata text-primary-color-11 text-center w-[167px]">
          Using Polis
        </Text>
        <Text p={"40px"} fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"} maxWidth={"650px"}
              justifyContent={"center"} textAlign={"center"}>
          How can our community of advisors helps learners
        </Text>
      </Stack>


      <SimpleGrid p={"40px"} columns={[1, null, 2]} spacing={"40px"}>

        <Center>
          <Box maxWidth={"500px"}>
            <Stack spacing={"60px"}>
              <Text fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>Higher Education Institutions</Text>
              <Text fontFamily={"lato"} color={"neutral-600"} fontWeight={"medium"} fontSize={"18"}>
                We provide higher educational organizations with access to a roster of top educational advisors who offer a wide range of academic and career services to their students.
              </Text>
              <ContactFormDrawer
                buttonText={"Request a Demo"}
              />
            </Stack>
          </Box>
        </Center>

        <Center>
          <Box maxWidth={"500px"}>
            <Image
              src="../mask-group6@2x.png"
            />
          </Box>
        </Center>


        <Center>
          <Box maxWidth={"500px"}>
            <Image
              alt=""
              src="../mask-group5@2x.png"
            />
          </Box>
        </Center>

        <Center>
          <Box maxWidth={"500px"}>
            <Stack spacing={"60px"}>
              <Text fontFamily={"lato"} color={"neutral-800"} fontWeight={"bold"} fontSize={"38"}>Individual Learners</Text>
              <Text fontFamily={"lato"} color={"neutral-600"} fontWeight={"medium"} fontSize={"18"}>
                We provide select individual access to adult learners (18+ years) such as professionals, full- and part-time students, lifelong learners, and anyone seeking to reconnect academically to pursue their educational goals.
              </Text>
              <RequestLearnerAccessDrawer />
            </Stack>
          </Box>
        </Center>



      </SimpleGrid>

    </Box>
    </Center>
  );
};

export default RequestLearnerAccessContainer;
