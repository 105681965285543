import { FunctionComponent } from "react";
import {Box, Button, Center, Circle, Image, Stack, SimpleGrid, Text} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";

const AboutHeroSection: FunctionComponent = () => {
  return (
    <Center bg={"secondary-color-3"} px={{ base: "8", lg: "5" }}>
      <Box position={"relative"} maxW={1200} h={700} w={"100%"}>
    {/*<Box position={"relative"} maxW={1440} h={[1400,1400, 1400, 1400, 700]} w={"100%"}>*/}
        <Image
          className="absolute  w-[328px] h-[163.5px]"
          right={{base: "-200px", lg: "0"}}
          alt=""
          src="../ellipse-114.svg"
        />
        <Circle
          position={"absolute"}
          left={{base: "-400px", lg: "-200px"}}
          size={400}
          bg={"secondary-color-5"}
        />
        <Image
          bottom={0}
          className="absolute right-[670px] w-[275px] h-[137px]"
          alt=""
          src="../ellipse-115.svg"
        />
        <Image
          bottom={0}
          className="absolute right-[0.73px] w-[334.27px] h-[334px]"
          alt=""
          src="../group-39755.svg"
        />
      <SimpleGrid position={"absolute"} columns={[1, 1, 1, 1, 2]}  w={"100%"} >
        <Center h={"100%"} w={"100%"} pb={20}>
        <Image
          position={"relative"}
          rounded={20}
          h={[0, 0, 0, 0,  700]}
          left={[null, null, 0]}
          top={[100]}
          src={"../AboutHeroImage.jpeg"}
        />
        </Center>
        <Center h={"100%"} w={"100%"} pb={20}>
          <ContentBlock
            title={"About US"}
            titleColor={"neutral-100"}
            subtitle={"1:1 learning is the oldest form of education"}
            subtitleColor={"neutral-100"}
            textAlign={"left"}
          >
            <Stack spacing={10}>
              <Text color={"neutral-100"}>
                New technologies have created opportunities to make 1:1 learning more accessible, connecting learners and educators across the globe. We partner with organizations — like universities and educational non-profits — to reduce student attrition and boost student engagement.
              </Text>
              <Text color={"neutral-100"}>
                Learners on our platform receive personalized advice, feedback, and guidance from educators as they navigate complex academic and professional spheres.
              </Text>
            </Stack>
          </ContentBlock>
        </Center>

        {/*<div className="absolute top-[152px] left-[calc(50%_+_133.5px)] w-[536px] h-[395px]">*/}
        {/*  <b className="relative top-[192px] left-[calc(50%_-_268px)] leading-[61px] inline-block w-[536px]">*/}
        {/*    1:1 learning is the oldest form of education*/}
        {/*  </b>*/}
        {/*  <b className="relative top-[0px] left-[calc(50%_-_268px)] text-xs tracking-[0.1em] leading-[18px] uppercase font-literata text-neutral-600">*/}
        {/*    About US*/}
        {/*  </b>*/}
        {/*  <div className="relative top-[245px] left-[calc(50%_-_268px)] text-base leading-[30px] font-medium inline-block w-[536px]">*/}
        {/*    New technologies have created opportunities to make 1:1 learning more*/}
        {/*    accessible, connecting learners and educators across the globe. Polis*/}
        {/*    enables learners to receive personalized advice, feedback, and*/}
        {/*    guidance from educators as they navigate complex academic and*/}
        {/*    professional spheres.*/}
        {/*  </div>*/}
        {/*</div>*/}

      </SimpleGrid>
        {/*<img*/}
        {/*  className="relative top-[100px] left-[calc(50%_-_486.5px)] w-[500px] h-[700px] object-cover"*/}
        {/*  alt=""*/}
        {/*  src="../mask-group20@2x.png"*/}
        {/*/>*/}
    </Box>
    </Center>
  );
};

export default AboutHeroSection;
