import React from 'react'
import {
  Drawer,
  DrawerHeader,
  DrawerContent,
  useDisclosure, Text,
} from '@chakra-ui/react'

type Props = {
  title?: string
  variant?: string
  children: React.ReactNode
}

const FAQDrawer: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Text as={"span"}
        className={"cursor-pointer"}
        color={"primary-color-1"}
        onClick={onOpen}
      >
        {props.title}
      </Text>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={['full', 'full', 'lg', 'xl', 'xl', 'xl']}
      >
        {/*<DrawerOverlay />*/}
        <DrawerHeader>
          {props.title}
        </DrawerHeader>
        <DrawerContent scrollBehavior={"smooth"} >
          {props.children}
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default FAQDrawer
