import React from "react";
import {Center} from "@chakra-ui/react";
import OikosLink from "../../OikosDesignSystem/OikosLink";
import FAQCard from "./FAQCard";
import FAQDrawer from "../../OikosDesignSystem/Layout/FAQDrawer";
import ToS from "../../OikosDesignSystem/Layout/ToS";
import PrivacyPolicy from "../../OikosDesignSystem/Layout/PrivacyPolicy";

const EducatorsFAQ: React.FC = () => {

  const educators = [
    {
      title: "How do I become an educator on Polis?",
      content:
        [
          <>Academics and professionals can apply to be an educator on Polis by filling out our <OikosLink href={"https://www.surveymonkey.com/r/poliseducatorintakeform"}>Educator Intake Form</OikosLink>. We will review your intake form and assess it based on your expertise, experience, and teaching. If the information you submit on your intake form is complete and thorough, we will schedule a brief virtual meeting with you to see if Polis is a good fit for you.</>,
          <>Please review our <FAQDrawer title={"Terms of Service"}><ToS/></FAQDrawer>, <FAQDrawer title={"Privacy Policy"}><PrivacyPolicy/></FAQDrawer>, and other policies in the footer of the website for more information about our expectations.</  >
        ]

    },
    {
      title: "What are the qualifications for becoming a Polis educator?",
      content: [
        "Our educators have a demonstrated record of excellence in teaching and advising in higher education. They bring experience in applying academic insights and experience to learners' professional needs and career questions.",
        "Virtually all of our educators have a minimum of a master's degree, with ~97% holding PhDs in their fields. However, excellence in teaching and the ability to bridge academic and career divides for learners are the most important qualifications for us.",
        "Unique expertise and experience as well as the ability to work with learners in higher education settings such as office hours, are extremely helpful too.",
        "If you have any questions, please contact us at polis@polis.ac."
      ]
    },
    {
      title: "Can I still be an educator on Polis if I have a full-time job at a university or company currently?",
      content: [
        "Yes, absolutely. Educators on Polis offer their services on a freelance basis and are paid as contractors. We have educators on our roster who have full-time jobs at universities and companies. However, if you are employed full-time or have any visa restrictions, you are responsible for making sure that you are allowed to engage in outside activities for payment."
      ]
    },
    {
      title: "Is being an educator on Polis a full-time job?",
      content: [
        "As an educator on Polis, you can set as much or as little availability as you wish. You also set your own hourly rate and can change it anytime. This flexibility allows you to create value for your expertise and experience anytime, anywhere, depending on your academic and professional commitments at any particular moment. Thus, all educators who offer office hours sessions on Polis do so on a freelance basis as contractors and are responsible for any tax obligations they may have."
      ]
    },
    {
      title: "Can I still use Polis if I run my own business?",
      content: [
        <>Yes, you can. Several of our educators on Polis own or run their own legally incorporated companies or consultancies. Offering office hours on Polis can be a way to add an additional revenue stream for an educators company or firm. Since we find, vet, and recommend learners to you, we ask that our educators DO NOT direct or take any client provided by Polis off-platform in any way for any engagement or service, as per our <FAQDrawer title={"Terms of Service"}><ToS/></FAQDrawer></>,
        <>If you have any questions about requests for offering services off of the Polis platform, please contact us at polis@polis.ac.</>
      ]
    },
    {
      title: "Will I receive onboarding and training before starting as a Polis educator?",
      content: [
        "Yes, we provide accessible onboarding materials and training to all Personal Educators. We also provide personalized live support and regular webinars to answer any questions you may have. Feel free to reach out to us anytime at polis@polis.ac. "
      ]
    },
    {
      title: "What are the technical requirements of using Polis?",
      content: [
        "You will need access to a reliable internet connection, a laptop or desktop computer with a Chrome or Firefox browser, a working camera, and a great attitude! :-) "
      ]
    },
    {
      title: "How does payment work for educators on Polis?",
      content: [
        "All payments on Polis are done solely through Stripe, the world’s most secure and trusted payment provider. Through our Stripe integration, educators are paid directly and securely to their bank account of choice. "
      ]
    },
    {
      title: "What can I offer as an educator on Polis?",
      content: [
        <>Your expertise and experience from your academic and professional life. We are creating opportunities for you to monetize expertise and experience in different formats for our learners. Currently, our first product is 1:1 virtual office hours. To learn more check out our <OikosLink href={"educators"}>Educators page</OikosLink>.</>
      ]
    },
  ]


  return (
    <Center w={"100%"} bg={"neutral-200"} minH={800}>
      <Center w={"100%"} h={"100%"} py={20}>
        <FAQCard
          title={"Educator Questions"}
          data={educators}
        />
      </Center>
    </Center>
  )
}

export default EducatorsFAQ;
