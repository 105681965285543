import React from 'react'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import OikosButton from "./OikosButton";

type Props = {
  buttonText: string
  title?: string
  variant?: string
  children: React.ReactNode
}

const OikosDrawer: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OikosButton
        onClick={onOpen}
        buttonText={props.buttonText}
        variant={props.variant}
      />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={['full', 'full', 'lg', 'xl', 'xl', 'xl']}
      >
        {/*<DrawerOverlay />*/}
        <DrawerHeader>
          {props.title}
        </DrawerHeader>
        <DrawerContent scrollBehavior={"smooth"} >
          {props.children}
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default OikosDrawer
