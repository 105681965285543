import { FunctionComponent } from "react";
import {
    Image,
    Box,
    SimpleGrid,
    Stack,
    Center,
    Container,
} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";

const AboutSection: FunctionComponent = () => {
    return (
        <div>
            <Stack spacing={'8rem'} pt={"32rem"} pb={"12rem"} mx={'auto'} maxWidth={"1200px"}>
                <Center px={"30px"}   w={"100%"}>
                    <SimpleGrid columns={[1, 1, 1, 1, 2]} spacingX={10}  w={"100%"}>
                        <Box pt={{ base: 0, lg: '4rem'}}>
                            <ContentBlock
                                title="Our mission"
                                titleColor={"primary-color-1"}
                                subtitle={ "Polis democratizes access to the world’s best educational advisors to improve educational outcomes for learners. "}
                                subtitleColor={"neutral-800"}
                                textAlign={"left"}
                            >
                                <div className="inline-block text-base font-medium text-neutral-600">
                                    We provide personalized 1:1 interactions for learners by matching them with the world’s best educational advisors to support their academic and career progress.
                                </div>
                            </ContentBlock>
                        </Box>

                        <Box w={"100%"} display={{base: 'none', lg: 'block'}}>
                            <Image
                            rounded={20}
                            w={"100%"}
                            src="../OurMission.jpeg"
                            />
                        </Box>

                    </SimpleGrid>
                </Center>

                <Center px={"30px"} w={"100%"} border={'1px solid'}>
                    <SimpleGrid spacingX={10}  columns={[1, 1, 1, 1, 2]}  w={"100%"}>

                        <Box w={"100%"} display={{base: 'none', lg: 'block'}}>
                            <Image
                            w={"100%"}
                            alt=""
                            src="../mask-group21@2x.png"
                            />
                        </Box>

                        <Box pt={{ base: 0, lg: '4rem'}}>
                            <ContentBlock
                            title="Our story"
                            titleColor={"primary-color-1"}
                            subtitle={ "Who we are"}
                            subtitleColor={"neutral-800"}
                            textAlign={"left"}
                            >
                                <Stack spacing={5}>
                                <div className="inline-block text-base font-medium text-neutral-600">
                                    We are a team of academics and professionals based in Cambridge, Massachusetts who believe that expanded access to 1:1 advising with top educators can transform higher education.
                                </div>
                                <div className="inline-block text-base font-medium text-neutral-600">
                                    Our educational advisors are professors, lecturers, PhD students, advanced graduate students, and professionals who bridge the academic and career divide.
                                </div>
                                {/*<div className="box-border shrink-0 border-t-[1px] border-solid border-neutral-400" />*/}

                                </Stack>
                            </ContentBlock>
                        </Box>

                    </SimpleGrid>
                </Center>
            </Stack>
        </div>
    );
};

export default AboutSection;
