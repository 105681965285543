import React from 'react';
import {Box} from "@chakra-ui/react";
import ToS from "../OikosDesignSystem/Layout/ToS";


const TermsOfService: React.FC = () => {

    return(
        <Box fontFamily={"lato"} color={"whiteAlpha.800"} fontSize={"4xl"} overflow={'hidden'} h={"950px"}>
            <Box  pt={"150px"} pb={20} px={5} textAlign={"center"} h={"100%"}>
                <ToS /> 
            </Box>
        </Box>
    )
}


export default TermsOfService