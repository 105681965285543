import { FunctionComponent } from "react";
import { Button, Center, Image, SimpleGrid, Container } from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import EducationPolicyDrawer from "../EducationPolicyDrawer";

const WhyPolis: FunctionComponent = () => {
    return (
        <Center background={"neutral-200"} py={8} px={{ base: "4", lg: "5" }}>
            <Center maxWidth={1200} px={8} w={"100%"}>
                <SimpleGrid columns={[1, 1, 1, 1, 2]} w={"100%"}>
                    <ContentBlock
                        title=""
                        titleColor={"primary-color-1"}
                        subtitle={"Why Polis?"}
                        subtitleColor={"neutral-800"}
                        textAlign={"left"}
                    >
                        <div className="relative text-base leading-[30px] font-medium text-neutral-600 inline-block">
                            <p>
                                Systematic, high-quality 1:1 advising has been
                                shown to reduce student attrition by up to 40%.
                                Working together with higher educational
                                institutions, we can reduce student attrition
                                and boost student engagement by pairing students
                                with our educational advisors.
                            </p>
                            <p className="[margin-block-start:0] [margin-block-end:0px]">
                                &nbsp;
                            </p>
                            <p>
                                Our platform provides higher education
                                organizations with access to experienced
                                advisors who meet with students in 1:1 virtual
                                advising sessions to address their personal
                                academic and career goals.
                            </p>
                            <p className="[margin-block-start:0] [margin-block-end:0px]">
                                &nbsp;
                            </p>
                            <p>
                                With our managed marketplace model and our
                                machine learning + human assessment tools, we
                                help learners find the right advisors to address
                                their educational needs and track learner
                                progress over time.
                            </p>
                            <p className="[margin-block-start:0] [margin-block-end:0px]">
                                &nbsp;
                            </p>
                            <p>
                                This approach allows higher education
                                organizations to greatly expand their roster of
                                academic advisors to meet the needs of their
                                students.
                            </p>
                        </div>
                        <Container py={20}>
                            <EducationPolicyDrawer />
                        </Container>
                    </ContentBlock>

                    <Center>
                        <Image
                            rounded={20}
                            w={"500px"}
                            src="../images2@2x.png"
                        />
                    </Center>
                </SimpleGrid>
            </Center>
        </Center>
    );
};

export default WhyPolis;
