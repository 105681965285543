import {FunctionComponent} from "react";
import {
  Container,
  Center,
  Button,
  Box,
  SimpleGrid,
  Stack,
  HStack,
  Image,
  Text
} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import BecomePolisEducatorDrawer from "../BecomePolisEducatorDrawer";



const NewWayToEngage: FunctionComponent = () => {

  return (
    <>

    {/*  */}
    <Center pb={20} px={{ base: '4', lg: '5' }} >
      <Box maxWidth={"1200px"} w={"100%"} >
        <SimpleGrid columns={[1,1, 1, 2, 2]}>
          <Center w={"100%"} h={"100%"} py={[10, 20, 10, 40]} display={{ base: "none", lg: "block"}} >
              <Image
                px={5}
                w={550}
                src="../images@2x.png"
              />
          </Center>
          <Center w={"100%"} h={"100%"} py={[10, 20, 10, 40]}>
            <ContentBlock
              title={"1:1 ADVISING"}
              titleColor={"primary-color-1"}
              subtitle={"A new way to engage"}
              subtitleColor={"neutral-800"}
              textAlign={"left"}
            >
              <Stack spacing={10}>
                <div className="inline-block font-medium text-neutral-600">
                  As universities rely ever more on adjunct appointments, traditional academic positions are hard to come by — and they often don’t pay well. Educators need a new way to engage with students beyond the university. This crisis has prompted us to build a company that could achieve the following:
                </div>
                <HStack>
                  <Image src="../ellipse-401.svg" />
                  <div className="inline-block font-medium text-neutral-600">
                    Enable greater educational opportunities for academics, PhDs,
                    lecturers, adjuncts, and graduate students to earn beyond the
                    university.
                  </div>
                </HStack>
                <HStack>
                  <Image src="../ellipse-401.svg" />
                  <div className="inline-block font-medium text-neutral-600">
                    Allow adult learners to connect with educators in 1:1 sessions
                    that bridge academic and career needs.
                  </div>
                </HStack>
                <HStack>
                  <Image src="../ellipse-401.svg" />
                  <div className="inline-block font-medium text-neutral-600">
                    Integrate the best insights from educational history and practice into an online marketplace.
                  </div>
                </HStack>
                <BecomePolisEducatorDrawer/>
              </Stack>
            </ContentBlock>

          </Center>
        </SimpleGrid>
      </Box>
    </Center>
    </>
  )

}

export default NewWayToEngage;
