import React, { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    Center,
    Stack,
    SimpleGrid,
    Image,
} from "@chakra-ui/react";
import OikosButton from "../../OikosDesignSystem/OikosButton";
import {
    SectionSubtitle,
    SectionTitle,
    SectionBody,
} from "../../OikosDesignSystem/Typography";

const PolisContainer: FunctionComponent = () => {
    const navigate = useNavigate();

    return (
        <Center py={40}>
            <Stack spacing={20} w={"100%"} alignItems={"center"}>
                <Box maxW={1200} h={"100%"} w={"100%"} px={5}>
                    <SimpleGrid columns={2} w={"100%"}>
                        <Box>
                            <Stack spacing={8}>
                                <SectionTitle
                                    textAlign={"left"}
                                    color={"primary-color-1"}>
                                    About Polis
                                </SectionTitle>
                                <SectionSubtitle
                                    textAlign="left"
                                    color={"neutral-800"}
                                    maxWidth={[350, 350, 450]}
                                >
                                    The future of education is personal
                                </SectionSubtitle>
                                {/*  <Image*/}
                                {/*    // h={540}*/}

                                {/*    w={674}*/}
                                {/*    src="../mask-group1@2x.png"*/}
                                {/*  />*/}
                            </Stack>
                        </Box>
                        <Flex justifyContent={"right"} w={"100%"}>
                            <SectionBody
                                pt={8}
                                textAlign={"left"}
                                color={"neutral-600"}
                                maxWidth={[525]}
                            >
                                Polis enables learners to receive personalized
                                advice, feedback, and guidance from educators as
                                they navigate complex academic and professional
                                spheres.
                            </SectionBody>
                        </Flex>
                    </SimpleGrid>
                </Box>

                {/*<Center>*/}

                <Box position={"relative"} maxW={1200} w={"100%"} h={700}>
                    <Image
                        position={"absolute"}
                        top={0}
                        left={5}
                        h={540}
                        // w={674}
                        src="../mask-group1@2x.png"
                    />
                    <Image
                        position={"absolute"}
                        right={0}
                        bottom={0}
                        h={612}
                        // w={768}
                        src="../mask-group2@2x.png"
                    />
                </Box>
                {/*</Center>*/}

                {/*<Center>*/}
                <OikosButton
                    buttonText={"Learn More"}
                    onClick={() => navigate("/howitworks")}
                    width={400}
                />
                {/*</Center>*/}
            </Stack>
        </Center>
    );
};

export default PolisContainer;
