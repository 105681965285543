import { FunctionComponent } from "react";
import {Center, Box, SimpleGrid, Stack, HStack, Image, Container} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";


const EducatorContainer: FunctionComponent = () => {
  return (
    <Center bg={"secondary-color-2"}>
    <Center maxWidth={"1200px"} w={"100%"} px={[0, 0, 5]} h={[1400, 1400, 1400, 1400, 700]} >
      <SimpleGrid  columns={[1, 1, 1, 1, 2]} w={"100%"} >
        <Container pb={20}>
        <ContentBlock
          title="HOW IT WORKS"
          titleColor={"primary-color-1"}
          subtitle={ "Expanding access to the world’s best educational advisors "}
          subtitleColor={"neutral-800"}
          textAlign={"left"}
        >
          <Stack spacing={5}>
          <div className="inline-block font-medium text-neutral-600">
            We provide organizations with personalized, data-driven, and cost-
            effective access to experienced educational advisors to reduce student
            attrition and boost engagement.
          </div>
            <div className="inline-block font-medium text-neutral-600">
              Learners meet in 1:1 office hours sessions with educators who can help
              them reach their academic and career goals.
          </div>
          <HStack pt={10} pl={5}>
            <Image
              alt=""
              src="../group12.svg"
            />
            <b className="inline-block">
              Educator Matching
            </b>
          </HStack>
          <HStack pl={5}>
            <Image
              alt=""
              src="../group12.svg"
            />
            <b className="inline-block">
              1:1 Virtual Office Hours
            </b>
          </HStack>
          <HStack pl={5}>
            <Image
              alt=""
              src="../group12.svg"
            />
            <b className="inline-block">
              Community & Support
            </b>
          </HStack>
          </Stack>
        </ContentBlock>
        </Container>



        <Box pos={"relative"} w={"100%"} h={"500px"} py={20}>
          <div className="absolute top-[0px] right-0 bg-secondary-color-3 w-[379px] h-[640px]" />
          {/*<div className="absolute top-[350px] bg-primary-color-11 w-[500px] h-[250px]" />*/}
          <Container
            position={"absolute"}
            top={350}
            bg={"primary-color-1"}
            maxW={500}
            w={"100%"}
            h={290}
          />
          <img
            className="absolute top-[219px] right-[0px] w-[261.27px] h-[261.27px]"
            alt=""
            src="../ellipse-1191.svg"
          />
          <img
            className="absolute top-[0px] w-[350px] h-[350px]"
            alt=""
            src="../rectangle-15141.svg"
          />

          <Center position={"absolute"} w={"100%"} px={5}>
            <Image
              rounded={20}
              // position={"absolute"}
              top={"50px"}
              left={[null, null, null, null, 50]}
              w={"100%"}
              maxW={500}
              src="../HowItWorksHero.jpeg"
            />
            </Center>

        </Box>

      </SimpleGrid>

     </Center>
    </Center>
  );
};

export default EducatorContainer;
