import React from 'react'
import OikosDrawer from "../OikosDesignSystem/Drawer";
import OikosButton from '../OikosDesignSystem/OikosButton';


const BecomePolisEducatorDrawer: React.FC = () => {

  return (
    <>
    <OikosButton
      buttonText={"Become a Polis Educator"}
      onClick={() =>{window.open("https://growth.polis.ac/s/educator-intake-form/tt-rbLgyTmaAZPmzvxnDmDwPy", "_blank", "noreferrer");}}
    />
      {/* <OikosDrawer
        buttonText={"Become a Polis Educator"}
      >
        <iframe
          height={"100%"}
          src={"https://growth.polis.ac/s/educator-intake-form/tt-rbLgyTmaAZPmzvxnDmDwPy"}
        />
      </OikosDrawer> */}
    </>
  )
}

export default BecomePolisEducatorDrawer
