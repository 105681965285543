import React from 'react'
// import OikosDrawer from "../OikosDesignSystem/Drawer";
import OikosButton from '../OikosDesignSystem/OikosButton';


const RequestLearnerAccessDrawer: React.FC = () => {

  return (
    <>
      <OikosButton
        buttonText={"Become a Polis Educator"}
        onClick={() =>{window.open("https://growth.polis.ac/s/Learner-Self-Assessment-1-v-1/tt-7fgmy5YGbJXVmohbt8U5Zi", "_blank", "noreferrer");}}
      />
      {/* <OikosDrawer
        buttonText={"Request Learner Access"}
      >
        <iframe
          height={"100%"}
          src={"https://growth.polis.ac/s/Learner-Self-Assessment-1-v-1/tt-7fgmy5YGbJXVmohbt8U5Zi"}
        />
      </OikosDrawer> */}
    </>
  )
}

export default RequestLearnerAccessDrawer
