import React from "react";
import {Box, Center, Circle, Container, Image, Stack, Show, Hide} from "@chakra-ui/react";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import OikosLink from "../../OikosDesignSystem/OikosLink";
import FAQCard from "./FAQCard";
import OikosDrawer from "../../OikosDesignSystem/Drawer";
import FAQDrawer from "../../OikosDesignSystem/Layout/FAQDrawer";
import ContactForm from "../ContactForm";


const FAQContactForm: React.FC = () => {
  return (
    <Container bg={"primary-color-1"} w={"100%"} maxW={"100%"} height={"100%"}>


      <Circle
        size={300}
        bg={"secondary-color-5"}
        position={"absolute"}
        top={0}
        right={0}
      />
      <Image
        // right={[0, 0, 0, 0, -200]}
        right={0}
        bottom={0}
        className="absolute max-w-[455px] h-[438px]"
        alt=""
        src="../rectangle-4032.svg"
      />

      <Container position={"relative"} pt={40}>
        <ContactForm />
      </Container>
    </Container>
  )
}

const GeneralFAQ: React.FC = () => {

  const general = [
    {
      title: "What is Polis?",
      content: [
        "Polis is an online education platform that provides organizations and individuals with personalized, data-driven access to the world’s best educators and their services — anytime, anywhere.",
        "Based in Cambridge, Massachusetts, Polis has two goals to transform higher and continuing education: 1) to democratize access to world’s best educators and 2) to create opportunities for educators to monetize their expertise and experience inside and outside of traditional higher education institutions."
        ]
    },
    {
      title: "How does Polis work?",
      content: [
        "Polis personalizes learners educational experience by helping the find answers and solutions to academic and career questions. We do this by matching and connecting learners with some of the world's best educators through our curated, proprietary matching system.",
        "We do this through 1:1 online office hours sessions. Office hours are more than just quick meetings with faculty or teaching assistants to get answers to your assignments in courses. Office hours are where 1:1 personal education takes place in higher education. Office hours allow you to ask questions and find answers in a wide range of areas and topics. They also allow for advising, guidance, and mentoring on academic and  career topics. Unlike lectures or seminars, office hours can be personalized completely to your needs and interests.",
        <OikosLink href={"howitworks"}>Learn more here.</OikosLink>
      ]
    },
    {
      title: "How is Polis different from other online options?",
      content: [
        "Polis differs from other options in two important ways.",
        "First, we are building a roster of the world’s best educators who bridge academic and career interests for learners. Our learners can access the services, expertise, and experience of educators from the top universities and organizations outside of academia to find answers to their academic and career questions at key transitions in their professional and personal life.",
        "Second, we have developed a proprietary method of assessing and matching learners and educators together to solve short-term challenges and optimize long-term educational outcomes. At the core of this unique approach are research-proven insights based on andragogy (the study of teaching adults) and evidence that technology should augment human components of educational experiences, not replace them.",
        // <>To learn more, check out our <OikosLink href='blog'>blog</OikosLink>.</>
      ]
    },
    {
      title: "Who are Polis's clients, and who can use the platform?",
      content: [
        "Polis provides subscription-based access to our educators to institutions and organizations who wish to improve educational outcomes of their students. We work currently with universities, colleges, and any institution involved in higher education. We also work with non-profit organizations in related areas of higher education.",
        <>We are exploring and testing options for enterprise and for-profit companies. If your company would be interested as a part of their workforce development, <FAQDrawer title={"please contact us"}><FAQContactForm/></FAQDrawer>.</>,
        <>Finally, we allow a number of individual users to access the Polis platform. To request non-institutional access, please fill out our <OikosLink href={"https://growth.polis.ac/s/Learner-Self-Assessment-1-v-1/tt-7fgmy5YGbJXVmohbt8U5Zi"}>Learner Intake Form</OikosLink> or contact us at polis@polis.ac.</>
      ]
    },
    {
      title: "Who are Polis's educators, and what do they offer?",
      content: [
        "Some of our educators are top academics in different fields from the world’s top universities. They are professors, lecturers, and advanced graduate students.",
        "Some of our educators are also professionals with advanced degrees and substantial experience in various industries and sectors. They are startup founders, consultants, executives, non-profit administrators, and teachers.",
        "Most of our educators possess advanced degrees (~95% have PhDs and terminal degrees, e.g., MBA, etc.). All of them have teaching experience and the ability to bridge the academic and career divide for students in higher education and the workforce — this is critical!  Our educators also receive onboarding and training resources to optimize their ability to personalize your educational experience on the platform.",
        "Polis educators offer their expertise and experience — whatever it may be — in a suite of services. Currently, the first way that our educators do that is through virtual 1:1 office hours sessions. ",
        <>To learn more what our educators offer, <OikosLink href={"howitworks"}>click here</OikosLink>.</>,
        <>To learn more about becoming an educator on Polis, check out our Educator page <OikosLink href={"educators"}>here</OikosLink>.</>
      ]
    },
    {
      title: "How can I get access to Polis at my organization or institution?",
      content: [
        "You can receive access to Polis via your institution or organization if they are partnered with us for a subscription.",
        "If your organization or institution doesn’t subscribe to Polis currently (and you would be interested), please contact us at polis@polis.ac to set up a demo of our platform."
      ]
    },
    {
      title: "How can I access Polis as an individual?",
      content: [
        "You can access the Polis platform in two ways.",
        "First, you can receive access to Polis via your institution or organization if they are partnered with us for a subscription.",
        <>Second, you can request individual access to the Polis by contact us or filling out our individual <OikosLink href={"https://growth.polis.ac/s/Learner-Self-Assessment-1-v-1/tt-7fgmy5YGbJXVmohbt8U5Zi"}>Learner Intake Form</OikosLink>.</>,
        "We will review your intake form and get back to you ASAP about receiving access to our platform.",
        "Polis is currently a web-based platform for desktop or laptop use only. We are developing a mobile application. Stay tuned!"
      ]
    }

  ]


  return (
    <Stack w={"100%"} alignItems={"center"} minH={800} pb={20}>
      <Center  w={"100%"}>

        <Stack w={"100%"} spacing={[-5, 0, -400]}>
        <Box position={"relative"} bg={"primary-color-1"} w={"100%"} h={['auto', 'auto', 640]}>

          {/* Triangle*/}
          <Hide below={"md"}>
            <Image
              position={"absolute"}
              top={0}
              h={208}
              w={208}
              src={"../rectangle-4378.svg"}
            />
          </Hide>
          {/* Yellow Circle */}
          <Circle position={"absolute"} size={300} bg={"secondary-color-5"} left={[-280, -150]} bottom={0}/>

          <Hide below={"md"}>
            <Circle position={"absolute"} size={208} bg={"secondary-color-5"} right={0} top={104}/>
            <Container
              position={"absolute"}
              w={208}
              h={208}
              top={0}
              right={0}
              bg={"secondary-color-3"}
            >
              <Image
                position={"absolute"}
                top={0}
                right={0}
                src={"../rectangle-4377.svg"}

              />

              <Circle position={"absolute"} size={208} bg={"secondary-color-6"} right={0} top={312}/>
            </Container>
          </Hide>

          <Container position={"relative"} pt={12}>
            <ContentBlock
              title="Frequently Asked Questions"
              titleColor={"neutral-500"}
              subtitle={"Have questions? We have answers."}
              subtitleColor={"neutral-200"}
              textAlign={"center"}
            >
            </ContentBlock>
          </Container>
        </Box>
        <Center width={"100%"} height={"100%"}>
          <FAQCard
            title={"General Questions"}
            data={general}
          />
        </Center>
        </Stack>

      </Center>

    </Stack>
  )
}

export default GeneralFAQ;
