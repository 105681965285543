import { FunctionComponent, useCallback } from "react";
import ContactFormSection from "../components/Home/ContactFormSection";
import PolisContainer from "../components/Home/PolisContainer";
import HomeHero from "../components/Home/HomeHero";
import SectionTest from "../components/Home/SectionTest";
import HowItWorksSection from "../components/Home/HowItWorksSection";
import {
  Flex,
  useBreakpointValue
} from "@chakra-ui/react";
import KeyBenefits from "../components/Home/KeyBenefits";


const Home: FunctionComponent = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (

      <Flex direction={"column"} flex={1} overflow={'hidden'}>
        <HomeHero />
        {/* <SectionTest /> */}
        <KeyBenefits />
        {isDesktop && <PolisContainer />}
        <HowItWorksSection />
        <ContactFormSection />
      </Flex>

  );
};

export default Home;
