import React, {FunctionComponent, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Box, Button, Center, SimpleGrid, Stack, Text, Image, Input, Square, Circle, Container} from "@chakra-ui/react";
import {SectionTitle} from "../Typography";
import {notification} from "antd";
import FooterDrawer from "./FooterDrawer";
import ToS from  "./ToS"
import PrivacyPolicy   from "./PrivacyPolicy";
import Section from "../Sections/Section";
import TwoColumnsGrid from "../TwoColumnsGrid";
import Column from "../Sections/Column";

type FooterLinkProps = {
  title: string;
  url: string;
}

const FooterLink: React.FC<FooterLinkProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Text
      className={"cursor-pointer"}
      fontFamily={"lato"}
      fontWeight={"medium"}
      fontSize={18}
      color={"neutral-600"}
      onClick={() => {navigate(props.url)}}
    >
      {props.title}
    </Text>
  )
}



const FooterSubscribeBox: React.FC = () => {
  const [email, setEmail] = useState<string>("")


  // patpYY9QUiLHh5Re7.77a472129708205b57d5b3502e04d7ca245afb8ca751b6cd59606e66dfd90853

  const onSubscribe = () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const body = {
      "email": email
    }

    const options = {
      method: "POST",
      headers,
      mode: "cors",
      body: JSON.stringify(body),
    }

    // @ts-ignore
    fetch("https://eo6u53rhb955hqj.m.pipedream.net", options)
      .then((response) => {
        notification.success({
          message: 'Success',
          description: 'Your have successfully subscribed to the newsletter',
        })
        setEmail('')
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: 'There was an error subscribing to the newsletter. Please try again later.',
        })
      })
  }


  return (
    <Container w={"100%"} h={340}  position={"relative"}>
      <Center position={"relative"}  minW={"100%"} h={340} bg={"primary-color-1"} rounded={20} overflow={"clip"}>
        <Square position={"absolute"} size={150} bottom={0} left={0} bg={"secondary-color-5"}/>
        <Circle position={"absolute"} right={-95} bottom={-10} size={190} bg={"secondary-color-6"}/>
        <Circle position={"absolute"} right={-95} top={-200} size={320} bg={"secondary-color-3"}/>
        <Stack position={"absolute"} spacing={5} fontFamily={"lato"} fontWeight={"bold"} fontSize={18}>
          <Text  color={"neutral-100"} fontSize={24}>Subscribe to our newsletter</Text>
          <Input
            placeholder={"Email address"}
            bg={"neutral-100"}
            color={"neutral-400"}
            onChange={(e) => {setEmail(e.target.value)}}
            value={email}
          />
          <Button
            bg={"neutral-100"}
            color={"primary-color-1"}
            onClick={onSubscribe}
          >
            Subscribe
          </Button>
        </Stack>
      </Center>
    </Container>
  )
}


const Footer: FunctionComponent = () => {


  return (
    <>
      <Section py={20}>
        

        <SimpleGrid columns={[1, 1, 1, 2, 2]} w={"100%"} maxW={1200} spacing={8}>

            <Stack spacing='2rem'>
            <SimpleGrid columns={[1, 1, 2,  2, 3]} spacingX={10} spacingY={5}  px={[5, 5, 100, 8]}>
                <FooterLink title={"Home"} url={"/"} />
                <FooterLink title={"Educators"} url={"/educators"} />
                {/*<FooterLink title={"Get in Touch"} url={"/"} />*/}
                {/*<FooterLink title={"Support"} url={"/support"} />*/}
                <FooterLink title={"About"} url={"/about"} />
                <FooterLink title={"FAQ"} url={"/faq"} />
                <FooterDrawer title={"Terms of Service"}>
                  <ToS/>
                </FooterDrawer>
                <FooterLink title={"How It Works"} url={"/howitworks"} />
                {/*<FooterLink title={"Blog"} url={"/blog"} />*/}
                <FooterDrawer title={"Privacy Policy"} >
                  <PrivacyPolicy/>
                </FooterDrawer>
                {/*<FooterLink title={"Resources"} url={"/resources"} />*/}


              </SimpleGrid>

              <Stack px={[5, 5, 100, 8]}>
                <Text
                  fontFamily={"lato"}
                  fontWeight={"medium"}
                  fontSize={18}
                  color={"neutral-600"}
                  maxWidth={550}
                >
                  Copyright &copy; {new Date().getFullYear()} Polis Educational Solutions, Inc.
                </Text>
                <Text
                  fontFamily={"lato"}
                  fontWeight={"medium"}
                  fontSize={18}
                  color={"neutral-600"}
                  maxWidth={550}
                >
                  All Rights Reserved
                </Text>
              </Stack>

            </Stack>
            <Box>
                <FooterSubscribeBox />
            </Box>
        </SimpleGrid>
      </Section>
    {/*<Center w={"100%"}>*/}
    {/*  <SimpleGrid columns={[1, 1, 2]} h={[1080, 1080, 540]} py={100} w={"100%"} maxW={1200}>*/}
    {/*    <Box maxW={600} w={"100%"}>*/}
    {/*      <Stack spacing={20} px={10}>*/}
    {/*        /!*<SectionTitle color={'primary-color-1'}>Pages</SectionTitle>*!/*/}
    {/*        <SimpleGrid columns={[1, 1, 1,  2, 3]} spacingX={10} spacingY={5}>*/}
    {/*          <FooterLink title={"Home"} url={"/"} />*/}
    {/*          <FooterLink title={"Educators"} url={"/educators"} />*/}
    {/*          /!*<FooterLink title={"Get in Touch"} url={"/"} />*!/*/}
    {/*          /!*<FooterLink title={"Support"} url={"/support"} />*!/*/}
    {/*          <FooterLink title={"About"} url={"/about"} />*/}
    {/*          <FooterLink title={"FAQ"} url={"/faq"} />*/}
    {/*          <FooterDrawer title={"Terms of Service"}>*/}
    {/*            <ToS/>*/}
    {/*          </FooterDrawer>*/}
    {/*          <FooterLink title={"How It Works"} url={"/howitworks"} />*/}
    {/*          /!*<FooterLink title={"Blog"} url={"/blog"} />*!/*/}
    {/*          <FooterDrawer title={"Privacy Policy"} >*/}
    {/*            <PrivacyPolicy/>*/}
    {/*          </FooterDrawer>*/}
    {/*          /!*<FooterLink title={"Resources"} url={"/resources"} />*!/*/}


    {/*        </SimpleGrid>*/}
    {/*        <Stack>*/}
    {/*          <Text*/}
    {/*            fontFamily={"lato"}*/}
    {/*            fontWeight={"medium"}*/}
    {/*            fontSize={18}*/}
    {/*            color={"neutral-600"}*/}
    {/*            maxWidth={550}*/}
    {/*          >*/}
    {/*            Copyright &copy; {new Date().getFullYear()} Polis Educational Solutions, Inc.*/}
    {/*          </Text>*/}
    {/*          <Text*/}
    {/*            fontFamily={"lato"}*/}
    {/*            fontWeight={"medium"}*/}
    {/*            fontSize={18}*/}
    {/*            color={"neutral-600"}*/}
    {/*            maxWidth={550}*/}
    {/*          >*/}
    {/*            All Rights Reserved*/}
    {/*          </Text>*/}
    {/*        </Stack>*/}
    {/*      </Stack>*/}
    {/*    </Box>*/}
    {/*    /!*<Container bg={"tomato"}>*!/*/}

    {/*    <Center position={"relative"} w={"100%"}>*/}

    {/*      <Center position={"absolute"} w={"100%"} maxW={450} h={340} bg={"primary-color-1"} rounded={20} overflow={"clip"}>*/}
    {/*        <Square position={"absolute"} size={150} bottom={0} left={0} bg={"secondary-color-5"}/>*/}
    {/*        <Circle position={"absolute"} right={-95} bottom={-10} size={190} bg={"secondary-color-6"}/>*/}
    {/*        <Circle position={"absolute"} right={-95} top={-200} size={320} bg={"secondary-color-3"}/>*/}
    {/*        <Stack position={"absolute"} spacing={5} fontFamily={"lato"} fontWeight={"bold"} fontSize={18}>*/}
    {/*          <Text  color={"neutral-100"} fontSize={24}>Subscribe to our newsletter</Text>*/}
    {/*          <Input*/}
    {/*            placeholder={"Email address"}*/}
    {/*            bg={"neutral-100"}*/}
    {/*            color={"neutral-400"}*/}
    {/*            onChange={(e) => {setEmail(e.target.value)}}*/}
    {/*            value={email}*/}
    {/*          />*/}
    {/*          <Button*/}
    {/*            bg={"neutral-100"}*/}
    {/*            color={"primary-color-1"}*/}
    {/*            onClick={onSubscribe}*/}
    {/*          >*/}
    {/*            Subscribe*/}
    {/*          </Button>*/}
    {/*        </Stack>*/}
    {/*      </Center>*/}

    {/*    </Center>*/}

    {/*    /!*</Container>*!/*/}
    {/*  </SimpleGrid>*/}
    {/*</Center>*/}
    </>
  );
};

export default Footer;
