import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import "./global.css";

const chakraTheme = extendTheme();
const emotionCache = createCache({
  key: "emotion-cache",
  prepend: true,
});

const container = document.getElementById("root");
const root = createRoot(container!);

export const theme = extendTheme(
  {
    colors: {
      gray: {
        "100": "#fefefe",
        "200": "#fbfcff",
        "300": "rgba(255, 255, 255, 0.25)",
      },
      whitesmoke: "#f1f1f1",
      black: "#000",
      dodgerblue: { "100": "#009eff", "200": "#1781fe", "300": "#157ee7" },
      silver: "#c4c4c4",
      "secondary-color-6": "#ff6262",
      "neutral-700": "#605c78",
      "secondary-color-2": "#f8f8ff",
      "secondary-color-3": "#0e2c70",
      "secondary-color-5": "#FFD600",
      "neutral-200": "#e7e8f1",
      "neutral-400": "#dcddeb",
      "neutral-800": "#14142b",
      "neutral-600": "#8184a3",
      "neutral-100": "#fff",
      "neutral-300": "#eff0f6",
      "neutral-500": "#bcbed1",
      "primary-color-11": "#103da0",
      "primary-color-1": "#103da0",
      "secondary-color-51": "#ffd600",
    },
    fontFamily: { lato: "Lato", literata: "Literata" },
    fontSize: {
      xs: "16px",
      sm: "16.14px",
      base: "18px",
      lg: "22px",
      xl: "24px",
      "2xl": "28px",
      "3xl": "31.07px",
      "4xl": "38px",
      "5xl": "40px",
      "6xl": "46px",
      "7xl": "54px",
    },
    borderRadius: {
      "3xxs": "10px",
      "2xxs": "13px",
      xxs: "13.83px",
      small: "14px",
      base: "20px",
      large: "22.53px",
      xxl: "80px",
      "2xxl": "136.35px",
    },
  },
  chakraTheme,
)


root.render(
  <BrowserRouter>
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </CacheProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
