import React from "react";
import {Center, Stack} from "@chakra-ui/react";
import {SectionBody, SectionSubtitle, SectionTitle} from "../Typography";

type Props = {
  title: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  textAlign: string;
  children?: React.ReactNode;
  [rest:string]: any;
}

const ContentBlock: React.FC<Props> = (props) => {
  const titleColor = props.titleColor || "primary-color-1";
  const subtitleColor = props.subtitleColor || "neutral-800";
  const rest = props.rest || {};

  return (
    <>
    {/*<Center h={"100%"} w={"100%"} {...rest} bg={"blue"}>*/}

      <Stack justifyContent={props.textAlign} maxW={"100%"} spacing={10}>
        <SectionTitle textAlign={props.textAlign} color={titleColor}>{props.title}</SectionTitle>
        {props.subtitle && <SectionSubtitle textAlign={props.textAlign} color={subtitleColor} maxWidth={"100%"}>{props.subtitle}</SectionSubtitle>}
        <SectionBody textAlign={props.textAlign} color={"neutral-600"} maxWidth={"100%"}>
          {props.children}
        </SectionBody>
      </Stack>
    {/*</Center>*/}
    </>
  );
}

export default ContentBlock;
