import React from "react";
import ImageLeftContentRight from "../../OikosDesignSystem/Sections/ImageLeftContentRight";
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";
import {
  Stack,
  Image,
  Center
} from "@chakra-ui/react";
import TwoColumnsGrid from "../../OikosDesignSystem/TwoColumnsGrid";
import Section from "../../OikosDesignSystem/Sections/Section";
import Column from "../../OikosDesignSystem/Sections/Column";

const OurCommunity: React.FC = () => {
  return (
    <>
    <Center px={{ base: '4', lg: '5' }}>
    <Section py={20}>
      <TwoColumnsGrid >
        <Column side={"left"}>
          <Image
            src={"../EducatorsOurCommunity.jpeg"}
            rounded={20}
            display={['none', 'none', 'block']}
          />
        </Column>
        <Column side={"right"}>
          <ContentBlock
            title={"Our Community"}
            subtitle={"A global learning community for the 21st century"}
            textAlign={"left"}
          >
            <Stack spacing={10}>
              <p>
                We’re building a community for the world’s best educational advisors who want to create value and impact for their expertise and experience outside of traditional higher ed institutions.
              </p>
              <p>
                Our educational advisors include professors, consultants, lecturers, entrepreneurs, graduate students, and professionals from a wide range of academic and non-academic backgrounds.
              </p>
              <p>
                Our educational advisors have prior experience at top universities and organizations around the world along with a demonstrated record of excellence in teaching, advising, and mentoring.
              </p>
            </Stack>
          </ContentBlock>
        </Column>
      </TwoColumnsGrid>
    </Section>
    </Center>
    {/*<ImageLeftContentRight imageSrc={"../EducatorsOurCommunity.jpeg"}>*/}
    {/*  <ContentBlock*/}
    {/*    title={"Our Community"}*/}
    {/*    subtitle={"A global learning community for the 21st century"}*/}
    {/*    textAlign={"left"}*/}
    {/*  >*/}
    {/*    <Stack spacing={10}>*/}
    {/*    <p>*/}
    {/*      We’re building a community for the world’s best educational advisors who want to create value and impact for their expertise and experience outside of traditional higher ed institutions.*/}
    {/*    </p>*/}
    {/*    <p>*/}
    {/*      Our educational advisors include professors, consultants, lecturers, entrepreneurs, graduate students, and professionals from a wide range of academic and non-academic backgrounds.*/}
    {/*    </p>*/}
    {/*    <p>*/}
    {/*      Our educational advisors have prior experience at top universities and organizations around the world along with a demonstrated record of excellence in teaching, advising, and mentoring.*/}
    {/*    </p>*/}
    {/*    </Stack>*/}
    {/*  </ContentBlock>*/}
    {/*</ImageLeftContentRight>*/}
    </>
  );
}

export default OurCommunity;
