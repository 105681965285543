import { FunctionComponent } from "react";
import ContactFormContainer from "./ContactFormContainer";
import { Box, Center, Container, SimpleGrid, Stack, Image, HStack, Text } from '@chakra-ui/react'
import ContentBlock from "../../OikosDesignSystem/Blocks/ContentBlock";

const ContactFormSection: FunctionComponent = () => {
  return (
    <Center bg={"neutral-100"} px={{ base: "4", lg: "5" }}>
      <Box maxW={1200} h={[1878, 1878, 1878, 939, 939]} w={"100%"} px={[5]}>
        <SimpleGrid columns={[1,1,1,2,2]} spacingX={40} h={"100%"} w={"100%"}>
          <Center h={"100%"} w={"100%"}>
            <Stack spacing={20}>
            <ContentBlock
              title={"Contact Us"}
              subtitle={"Interested to learn more? Send us a message."}
              textAlign={"left"}
            >
                <p>If you need support or assistance, please write to us here. Our general response time is one business day</p>.
            </ContentBlock>
              <Container w={300} h={100} bg={"neutral-100"} shadow={"lg"} rounded={20}>
              <Center h={"100%"}>
                <HStack>
                  <Image
                    src="../mailicon.svg"
                  />
                  <Stack >
                    <Text fontWeight={"bold"} fontSize={18} color={"neutral-800"}>
                      Email us
                    </Text>
                    <Text fontWeight={"medium"} fontSize={16} color={"neutral-600"}>
                      polis@polis.ac
                    </Text>
                  </Stack>
                </HStack>
              </Center>
              </Container>
            </Stack>
          </Center>

          <ContactFormContainer />
        </SimpleGrid>
      </Box>

    </Center>
  );
};

export default ContactFormSection;
