import { FunctionComponent, useCallback } from "react";
import {
  Card,
  Center,
  Container,
  Circle,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Box,
  Image,
  Text,
  Stack,
  Flex
} from "@chakra-ui/react";
import GeneralFAQ from "../components/FAQ/GeneralFAQ";
import EducatorsFAQ from "../components/FAQ/EducatorsFAQ";
import LearnersFAQ from "../components/FAQ/LearnersFAQ";


const FAQ: FunctionComponent = () => {

  return (
    <Flex direction={"column"} flex={1}>
      <GeneralFAQ />
      <EducatorsFAQ/>
      <LearnersFAQ />
    </Flex>
  );
};

export default FAQ;
