import React from 'react'
import { Center, Flex } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode
  side: "left" | "right"
  bg?: string
  disableCentering?: boolean
  [rest:string]: any;
}
const Column: React.FC<Props> = (props) => {
  const rest = props.rest || {};

  if (props.disableCentering) {
    return (
      <Flex
        maxW={560}
        // w={['100%', 460, 460, 560, 560]}
        w={'100%'}
        bg={props.bg}
        justifyContent={props.side}
        {...rest}
      >
        {props.children}
      </Flex>
    )
  }

  return (
    <Flex
      maxW={560}
    //   w={['100%', 460, 460, 560, 560]}
        w={'100%'}
        bg={props.bg}
        justifyContent={props.side}
        right={props.side === "right" ? -100 : undefined}
        {...rest}
    >
      <Center h={"100%"}>
        {props.children}
      </Center>
    </Flex>
  )
}

export default Column
