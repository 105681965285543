import React from "react";
import OikosDrawer from "../OikosDesignSystem/Drawer";
import ContentBlock from "../OikosDesignSystem/Blocks/ContentBlock";
import {Container, Stack} from "@chakra-ui/react";
import {SectionTitle} from "../OikosDesignSystem/Typography";

const EducationPolicyDrawer: React.FC = () => {
  return (
    <OikosDrawer
      buttonText={"Read Our Education Integrity Policy"}
    >
      <Container maxWidth={"100%"} width={"100%"} p={10} color={"neutral-600"} >
        <Stack spacing={5}>
          <SectionTitle textAlign={"left"} color={"secondary-color-6"}>{"Education Integrity Policy"}</SectionTitle>
        <p>
        As an educator-run private company, we hold ourselves as well as our users and affiliates to the highest professional and ethical standards for educational integrity.
        </p><p>
        Each registered user - learner and educator – on our platform accepts our Term of Service when signing up and creating an account. By doing that, every user must respect the academic trademarks of universities and college with which our educators are currently or were affiliated as students, alumni, and/or faculty.
      </p><p>
        Users on Polis must also accurately represent their current and former status at any university or organization. This includes, but is not limited to, their teaching, student, degree, alumni, or any other status related any university or organization mentioned in their profiles.
      </p><p>
        Moreover, users on Polis agree to be familiar with and honor any academic integrity or honesty policy, outside activity policy, and any related policies required of them as faculty, students, and alumni/ae. Educators and learners are responsible for understanding their own institutions’ policies and ensuring that their activities on Polis comply with their institution’s policies. Polis will not under any circumstances knowingly ask educators to violate such policies, but is not responsible for checking users’ compliance with their own institutions’ policies. Users must inform us of all potential issues or changes in policy at their respective institutions.
      </p><p>
        As a private company, Polis affirms that it has no current academic or degree-granting affiliation with any university or educational institution. Whether on or off the Polis platform, registered learner and educator users will not engage in any exchange that involves preferential treatment in admissions, the forced writing of letters of recommendations, the completion of writing or assignments (e.g. plagiarism), or any form of academic dishonesty. This principle applies to any offer or request that would run contrary to the academic, admissions, and conduct policies of any academic institution which users are or may be affiliated with.
      </p><p>
        Finally, Polis reserves the right to limit, deny, or terminate access to our platform to users who do not abide by our Terms of Service and this policy. We also reserve the right to be the final arbiter of what entails “educational integrity” on our platform.
      </p><p>
        If necessary, we will seek legal and financial remedies for any damages caused to our company and community of users for any intentional and malicious undermining of our Terms of Service and this policy.
      </p><p>
        If you have any questions about our policy, please contact us at polis@polis.ac.
      </p>
        </Stack>
      </Container>
    </OikosDrawer>
  )
}

export default EducationPolicyDrawer;
