import React from 'react';
import {Box} from "@chakra-ui/react";
import PrivacyPolicy from "../OikosDesignSystem/Layout/PrivacyPolicy";


const Privacy: React.FC = () => {

    return(
        <Box fontFamily={"lato"} color={"whiteAlpha.800"} fontSize={"4xl"} overflow={'hidden'} h={"950px"}>
            <Box  pt={"150px"} pb={20} px={5} textAlign={"center"} h={"100%"}>
                <PrivacyPolicy />
            </Box>
        </Box>
    )
}

export default Privacy