import React, { FunctionComponent } from "react";
import { Container, Box, Input, Textarea, SimpleGrid, Image, Stack } from "@chakra-ui/react";
import ContactForm from "../ContactForm";

const ContactFormContainer: FunctionComponent = () => {
  return (
    <Box position={"relative"} py={20}>
      <Container
        position={"absolute"}
        top={0}
        // right={[0, 0, 0, 0, -200]}
        right={10}
        bg={"primary-color-1"}
        w={["100%", "100%", "100%", "100%", "420px"]}
        h={939}
      />
        <Image
          // right={[0, 0, 0, 0, -200]}
          right={10}
          className="absolute top-[0px] w-[280px] h-[280px]"
          alt=""
          src="../ellipse-407.svg"
        />
        <Image
          // right={[0, 0, 0, 0, -200]}
          right={20}
          className="absolute top-[501px] max-w-[350px] h-[503px]"
          alt=""
          src="../rectangle-4032.svg"
        />

      <Container
        position={"relative"}
        rounded={20}
        bg={"neutral-100"}
        shadow={"lg"}
        maxW={550}
        w={["100%", "100%", "100%", "100%"]}
        h={739}
        fontFamily={"lato"}
        fontSize={"base"}
        color={"neutral-800"}
        p={10}
      >
          <ContactForm />

      </Container>
    </Box>
  );
};

export default ContactFormContainer;
