import { FunctionComponent } from "react";
import {
  Box,
  Center,
  Image,
  Stack
} from "@chakra-ui/react";
import { SectionSubtitle, SectionTitle, SectionBody } from "../../OikosDesignSystem/Typography";
import Section from "../../OikosDesignSystem/Sections/Section";

const DiversitySection: FunctionComponent = () => {

  return (
    <Section bg={"primary-color-11"} >
      <Box position={"relative"} w={"100%"} h={445} overflow={'hidden'}>
        {/*Red Circle*/}
        <Image
          position={"absolute"}
          // top={400}
          bottom={0}
          w={380}
          h={275}
          right={['-300px', 0]}
          src="../ellipse-1181.svg"
        />
        {/*Yellow Circle*/}
        <Image
          position={"absolute"}
          // top={[-172, "calc(100vw - 100%)", "calc(100vw - 100%)", 331, 331]}
          bottom={0}
          left={['-250px', 0]}
          w={338}
          h={344}
          src="../ellipse-1193.svg"
        />
        {/*Blue Trianglw*/}
        <Image
          position={"absolute"}
          top={['-10px', 0]}
          left={['-300px', 0]}
          w={424}
          h={424}
          src="../rectangle-15142.svg"
        />

        <Center position={"relative"} pt={10} px={10}>
          <Stack position={"relative"} spacing={[5, 20]} justifyContent={"center"} maxW={600}>
            <SectionTitle position={"relative"} textAlign={"center"} color={"secondary-color-6"}>Core Values</SectionTitle>
            <SectionSubtitle textAlign={"center"} color={"secondary-color-2"}>Committed to diversity, equity & inclusion</SectionSubtitle>
            <SectionBody textAlign={"center"} color={"secondary-color-2"} maxWidth={600}>We believe that diversity, equity and inclusion are essential to our mission of creating a more equitable world. We are committed to building a diverse and inclusive community of educators and learners.</SectionBody>
          </Stack>
        </Center>

      </Box>
    </Section>
  );
};

export default DiversitySection;
