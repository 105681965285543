import { FunctionComponent, useCallback } from "react";
import { Box, Center, Container, SimpleGrid, Text, Stack, Button, Image, Flex } from "@chakra-ui/react";
import ContactFormDrawer from "../ContactFormDrawer";
const GrowthMarketingPlaybookContain: FunctionComponent = () => {
  const onPrimaryBTNClick = useCallback(() => {
    // Please sync "Contact" to the project
  }, []);

  const onPrimaryBTN1Click = useCallback(() => {
    // Please sync "Contact" to the project
  }, []);

  return (
    <>
      {/*<Center>*/}
        <Flex position={"relative"} w={"l"} h={[1434, 1434, 1434, 717, 717]} >
          {/*<Box position={"absolute"} pt={84} w={"100%"} bg={"primary-color=11"}>*/}
          {/*<div className="absolute top-[84px]  bg-primary-color-11 w-full h-[717px]" />*/}
          <Container position={"absolute"} bottom={[6, 0]} bg={"primary-color-11"} pt={84} h={[1434, 1434, 1434, 717, 717]} overflow={'hidden'} w={"full"} maxW={"full"}/>
            <img
                className="absolute bottom-0 right-0 w-[460px] h-[316px]"
                alt=""
                src="../ellipse-118.svg"
          />
          <Container
            position={"absolute"}
            left={0}
            bg={"secondary-color-5"}
            maxWidth={500}
            w={"100%"}
            h={[400, 480, 717]}
          />

          {/*</Box>*/}

          <Center position={"absolute"} w={"100%"}>
          <SimpleGrid maxWidth={"1200px"} w={"100%"} p={"40px"} columns={[1, 1, 1, 2, 2]} gap={20}>
            <Box py={"40px"} pt={[0, null, -60]} maxWidth={600} justifyContent={"right"}>
              <Image
                w={500}
                // h={700}
                src="../mask-group4@2x.png"
              />
            </Box>
            <Center>
            <Box  py={"40px"} pt={"100px"} height={"100%"} fontFamily={"lato"} color={"neutral-100"} maxWidth={"600px"} >

              <Stack spacing={"60px"}>
                <Text fontWeight={"bold"} fontSize={38}>
                  Why Polis?
                </Text>
                <Text fontWeight={"medium"} fontSize={18} maxWidth={500}>
                  Systematic, high-quality 1:1 advising has been shown to reduce the cost of student attrition by at least 40%. Working together with higher ed institutions to provide access to our roster of advisors, we can reduce student attrition and boost student engagement.
                </Text>
                <Text fontWeight={"medium"} fontSize={18} maxWidth={500}>
                  Our platform provides higher education organizations with access to experienced advisors who meet with students in 1:1 virtual advising sessions to address their personal academic and career goals.
                </Text>
                <ContactFormDrawer
                  buttonText={"Contact Us"}
                  variant={"outline"}
                />
              </Stack>

            </Box>
          </Center>
          </SimpleGrid>
          </Center>

        </Flex>
    </>
  );
};

export default GrowthMarketingPlaybookContain;
