import React, { useState } from 'react';
import OikosDrawer from '../OikosDesignSystem/Drawer';
import OikosInput from '../OikosDesignSystem/OikosInput';
import {
  Circle,
  Container,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Stack,
  HStack,
  Textarea, Image,
} from "@chakra-ui/react";
import OikosButton from "../OikosDesignSystem/OikosButton";
import ContactForm from "./ContactForm";

type Props = {
  buttonText: string
  variant?: string
}

const ContactFormDrawer: React.FC<Props> = (props) => {



  return (
    <OikosDrawer
      buttonText={props.buttonText}
      title={"Contact Us"}
      variant={props.variant}
    >
      <Container bg={"primary-color-1"} w={"100%"} maxW={"100%"} height={"100%"}>


        <Circle
          size={300}
          bg={"secondary-color-5"}
          position={"absolute"}
          top={0}
          right={0}
        />
        <Image
          // right={[0, 0, 0, 0, -200]}
          right={0}
          bottom={0}
          className="absolute max-w-[455px] h-[438px]"
          alt=""
          src="../rectangle-4032.svg"
        />

        <Container position={"relative"} pt={40}>
          <ContactForm />
        </Container>
      </Container>
    </OikosDrawer>
  )

}

export default ContactFormDrawer
